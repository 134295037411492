import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import BootstrapVueIcons from 'bootstrap-vue';
import { i18n } from './plugins/i18n';
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';
import VuePromiseBtn from 'vue-promise-btn';
import VueTelInput from 'vue-tel-input';
import AlertPlugin from './plugins/AlertPlugin';
import SelectInput from '@/components/form/SelectInput.vue';
import AOS from 'aos'
import 'aos/dist/aos.css'


// not required. Styles for built-in spinner
import 'vue-promise-btn/dist/vue-promise-btn.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueTelInput);
Vue.use(VuePromiseBtn);
Vue.use(AlertPlugin);
Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.component('select-input', SelectInput);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  created: () => AOS.init(),
}).$mount('#app');
