import { render, staticRenderFns } from "./CadastroEscolherPlano.vue?vue&type=template&id=6b263aeb&scoped=true&"
import script from "./CadastroEscolherPlano.vue?vue&type=script&lang=ts&"
export * from "./CadastroEscolherPlano.vue?vue&type=script&lang=ts&"
import style0 from "./CadastroEscolherPlano.vue?vue&type=style&index=0&id=6b263aeb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b263aeb",
  null
  
)

export default component.exports