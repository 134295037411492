import { render, staticRenderFns } from "./SpanishTerms.vue?vue&type=template&id=8a7321ce&scoped=true&"
import script from "./SpanishTerms.vue?vue&type=script&lang=ts&"
export * from "./SpanishTerms.vue?vue&type=script&lang=ts&"
import style0 from "./SpanishTerms.vue?vue&type=style&index=0&id=8a7321ce&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a7321ce",
  null
  
)

export default component.exports