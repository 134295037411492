












































import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import Header from '@/components/layouts/Header.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import CadastroEmpresaModal from '@/components/modal/CadastroEmpresaModal.vue';

import { Validate } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import notFreeMail from '@/core/validations/notFreeMail';
import { siteCompanyEndPoint } from '@/core/endpoint';

const VueScrollTo = require('vue-scrollto');

@Component({
  components: {
    'app-section': Section,
    'app-header': Header,
    TextInput,
    CheckboxInput,
    TelInput,
    CadastroEmpresaModal
  },
})
export default class SectionHeader extends Vue {
  public planid: string = "free";

  @Validate({ required })
  private responsavel: string = '';

  @Validate({ required, email, notFreeMail })
  private empresaEmail: string = '';

  @Validate({ required })
  private empresaTelefone: string = '';

  @Validate({ checked: sameAs( () => true ) })
  private aceitoTermos: boolean = false;

  public openModalCadastro() {
    this.$emit('registryClicked',true)
    this.$bvModal.show('cadastro-empresa-modal')
  }

  public succesRegistry(data: any) {
    let frontBase = siteCompanyEndPoint;
    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
          `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
            "access_token"
          )}&redirectTo=/home?lang=${this.$i18n.locale}`
        );
    }
  }
  public erroRegistry(type: string){
    
   
  }

}
