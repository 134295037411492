
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { clientId} from '../endpoint';
import Repository from '@/core/Services/Repository';


export default class ClientAuthentication extends Repository {
  constructor() {
    super(new ApiRouter({
        auth: { url: '/v1/c-t', method: 'POST', headers: {} },
        me: { url: '/v1/me', method: 'GET', headers: {} } }) );
  }

  public getRandom() {
    const min: number = Math.ceil(Math.random() * 10);
    const max: number = Math.floor(Math.random() * 100);
    const x1 = '' + Math.floor(Math.random() * (max - min)) + min;
    const x2 = '' + Math.floor(Math.random() * (max - min)) + min;
    return btoa(x1 + x2).replace(/=/g, '');
  }

  public auth(): Promise<any> {
    return this.fetchToken();

    // const scs: string = this.getRandom();
    // const stringData = scs + '==' + btoa(JSON.stringify({_: clientId + scs + token}));
    // const data: any = { _: btoa(stringData) };
    // try {
    //   data.platform = navigator.platform;
    //   data.userAgent = navigator.userAgent;
    //   data.appVersion = navigator.appVersion;
    // } catch (e) {
    //   console.log('Could not set navigator settings')
    // }
    // return super.auth(data).then((d1: any) => {
    //   const o: string = atob(d1);
    //   const d: string = o.substring(0, o.indexOf('.'));
    //   const c = atob(d);
    //   const e = JSON.parse(c);
    //   return e;
    // });
  }


  public async fetchToken() {
    return new Promise((resolve,reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITEKEY, {action: 'login'}).then(resolve).catch(reject);
      });
    })
  }

}
