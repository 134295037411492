























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: ''}) public value!: string;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;
  
  public scopeID: string;

  constructor() {
    super();
    this.scopeID = ((new Date()).getTime()) + '_' + Math.ceil((Math.random()*100));
  }

  private get inputClasses() {
    return {
      [this.variant]: !!this.variant,
      'was-validated': null !== this.state,
      'is-invalid': false === this.state,
      'is-valid': this.state,
    };
  }


  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
