




































import { Prop, Component, Vue } from 'vue-property-decorator';
import TextInput from '@/components/inputs/TextInput.vue';
import TextArea from '@/components/inputs/TextArea.vue';
import ContatoService from '@/core/Services/Basic/Contato';
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    TextInput,
    TextArea,
    Alert
  },
})
export default class ContatoForm extends Vue {
  public nome: string = "";
	public email: string = "";
	public telefone: string = "";
	public assunto: string = "";
	public mensagem: string = "";
	public contatoService: ContatoService = new ContatoService();
	public load: boolean = false;
  public alert: Alert = new Alert();

	public sendContato(){
		this.load = true;
		this.contatoService.create({
			nome: this.nome,
      email: this.email,
      telefone: this.telefone,
			assunto: this.assunto,
			mensagem: this.mensagem
		}).then((data: any) => {
			this.nome = "";
			this.email = "";
			this.assunto = "";
			this.mensagem = "";
      this.load = false;
      this.alert.title = ""+this.$t('Obrigado')
      this.alert.addMsg(this.$t('Seu contato foi enviado para nossa equipe, logo iremos respondê-lo.'));
      this.alert.callBoxSuccess()
		}).catch((err: any) => {
			this.load = false;
			console.log(err)
		})
	}
}
