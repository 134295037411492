




























import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    'app-section': Section,
    Carousel,
    Slide
  },
})
export default class SectionDepoimentos extends Vue {
  public depoimentos: any[] = [];

  get isMobile() {    
      return ( window.innerWidth <= 800 );
  }

  public created() {
    this.depoimentos = [
      {
        text: 'A Jornada de transformação da Oracle continua e ainda existem percalços como a diversidade da força de trabalho, que buscamos resolver com métodos como o recrutamento anônimo da Jobecam. <strong>Está é uma parceria que cresce junto com a nossa transformação.</strong>',
        who: 'Rodrigo Galvão, Presidente Oracle Brasil'
      },
      {
        text: 'Com a seleção anônima da Jobecam realmente percebi que quando a gente conversa com a pessoa prestamos atenção no conteúdo que ela está trazendo e a gente não quer ver outras coisas, <strong> focamos no que é importante que é as habilidades e competências do talento.</strong>',
        who: 'Sarah Hirota, People & Culture e Co-Founder da Fhinck'
      },
      {
        text: 'E de mais valioso foi não conseguir usar os vieses que a gente tem. Você não reconhece, <strong> você foca naquilo que importa que é escolher as pessoas por competência</strong>',
        who: 'Angélica Martins, Líder de Aquisição de Talentos SR - América do Sul na Honeywell'
      },
      {
        text: 'Acredito que o processo anônimo aumentou o rendimento, uma vez que <strong>permitiu que a avaliação das pessoas entrevistadas fosse feita com profundidade em relação a habilidades técnicas</strong>, com foco nas experiências e conhecimento de cada uma',
        who: 'Empresa Raízen'
      }
    ];
  }
}
