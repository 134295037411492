
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class SelectInput extends Vue {
  @Prop({default: ''}) public variant!: string;
    
    @Prop({default: null})
    public value!: any;

    @Prop({default: false})
    public required!: boolean;

    @Prop({default: false})
    public disabled!: boolean;
    
    @Prop({default: () => {return null}})
    public placeholder!: object;
    
    @Prop({default: () => {return null}})
    public label!: string;
    
    @Prop({default: () => {return {}; }})
    public optionList!: any[];

    @Prop({default: null}) 
    public state!: null|boolean|string;


    public scopeID: string;
    public currentValue: any|null = null;

    @Watch('value')
    public valueChanged(){
      this.currentValue = this.value;
    }

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput() {
        this.$emit('input', this.currentValue);
    }

    public handleChange() {
        this.$emit('change', this.currentValue);
    }

    public mounted() {
        setTimeout(() => {
            this.currentValue = this.value;
        },1500)
    }

}
