

















import {Vue, Prop, Component} from 'vue-property-decorator';
import { Action } from "vuex-class";

const namespace: string = "profile";

@Component
export default class Redirect extends Vue {
  public contentText: string = "";
  @Prop() private url!: string;

  public created() {
    this.contentText = "" + this.$t("Aguarde carregando");
    setTimeout(() => {
      window.location.href = this.url+'?lang='+this.$i18n.locale;      
    }, 1000);
  }
}
