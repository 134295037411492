
















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import TextInput from '@/components/inputs/TextInput.vue';
import EmpresaService from '@/core/Services/Empresa/Empresa';
import TelInput from '@/components/inputs/TelInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import { EmpresaEntity, EmpresaTrialAccount, PlanoCreditoEntity } from '@/core/models/Entities';
import { PasswordStrengh } from '@/core/util'
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import { Validate } from 'vuelidate-property-decorators';
import { required, sameAs, email, requiredIf } from 'vuelidate/lib/validators';
import notFreeMail from '@/core/validations/notFreeMail';
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    TextInput,
    CheckboxInput,
    TelInput
  },
})
export default class CadastroDadosDeAcesso extends Vue {
  public promise: Promise<any> |null = null;
  private empresaService = new EmpresaService();
  private empresa: EmpresaEntity = new EmpresaEntity();
  private planoCreditosService = new PlanoCredito();

  private trialAccount: EmpresaTrialAccount|null = null;
  private planoEscolhido: PlanoCreditoEntity|null = null;

  private alert: Alert = new Alert();

  @Prop({default: null})
  public planid!: string|null;

  @Prop({default: null})
  public btnText!: string|null;

  @Validate({ required })
  private nomeEmpresa: string = '';

  @Validate({
    required,
    hasOneLower: PasswordStrengh.hasOneLower,
    hasOneUpper: PasswordStrengh.hasOneUpper,
    hasOneNumber: PasswordStrengh.hasOneNumber,
    hasOneSpecialChar: PasswordStrengh.hasOneSpecialChar,
    hasEightChars: PasswordStrengh.hasEightChars,
    hasSequence: PasswordStrengh.checkSequence,
    //valid: PasswordStrengh.isValidPassword,
  })
  private password: string = '';

  @Validate({ required, matchsPassword: sameAs('password') })
  private passwordConfirmation: string = '';

  @Validate({ required })
  private responsavel: string = '';

  @Validate({ required, email, notFreeMail })
  private empresaEmail: string = '';

  @Validate({ required })
  private empresaTelefone: string = '';

  @Validate({ checked: sameAs( () => true ) })
  private aceitoTermos: boolean = false;

  private showEmailField = false;



  public async created() {
    
    if(!sessionStorage.getItem('access_token')) {
      sessionStorage.removeItem('registroEmpresa')
      sessionStorage.removeItem('dadosEmpresa')
    }

    const registroEmpresa = sessionStorage.getItem('registroEmpresa');
    const dadosEmpresa = sessionStorage.getItem('dadosEmpresa');


    if(dadosEmpresa){
      if(this.$i18n.locale == 'pt-BR'){
        this.$router.push({ name: 'CadastroPagamento', query: {lang: this.$i18n.locale} });
      }else{
        this.$router.push({ name: 'CadastroDadosInvoice', query: {lang: this.$i18n.locale} });
      }
    }

    if (registroEmpresa){
      this.trialAccount = JSON.parse(registroEmpresa)
      this.empresaTelefone = this.trialAccount!.telefone+"";
      this.empresaEmail = this.trialAccount!.email+"";
      this.responsavel = this.trialAccount!.nomeResponsavel+"";
      this.aceitoTermos = true;
    }
  }

  public async submitForm() {
    this.$v.$touch();
    if (this.$v.$invalid)
      return;

    if(!this.trialAccount){
      this.empresa.primeironome = this.responsavel;
      this.empresa.perfilempresa!.telefones = this.empresaTelefone;
      this.empresa.email = this.empresaEmail;
    }else{
      this.empresa.primeironome = this.trialAccount!.nomeResponsavel;
      this.empresa.perfilempresa!.telefones = this.trialAccount!.telefone;
      this.empresa.email = this.trialAccount!.email;
    }

    this.empresa.perfilempresa!.nomeEmpresa = this.nomeEmpresa;
    this.empresa.senha = this.password;
    this.empresa.lingua = this.$i18n.locale;

    let tel = ""+this.empresa.perfilempresa!.telefones;

    var RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    if(!RegExp.test(tel) ||tel.replace(/([^\d])+/gim, '').length < 10 ){
      this.alert.title = ""+this.$t('Alerta')
      this.alert.addMsg(this.$t('Telefone inválido!'));
      this.alert.callBoxDanger();
      this.$emit('invalidForm')
      return;
    }

    if(this.planid){
      this.empresa.plano_id = this.planid;
    }
    
 
    this.promise = this.empresaService.create(this.empresa)
      .then((data:any) => {
        // let frontBase = siteCompanyEndPoint;
        
        sessionStorage.setItem('access_token', data['accessToken']);
        sessionStorage.setItem('token_type', 'Bearer');
        sessionStorage.setItem('profile_type', 'company');

        this.trialAccount = {
          nomeEmpresa: this.nomeEmpresa,
          email: this.empresaEmail,
          telefone: this.empresaTelefone,
          nomeResponsavel: this.responsavel,
          lang: this.$i18n.locale,
          ebook: false
        };

        sessionStorage.setItem('dadosEmpresa', JSON.stringify(data));
        sessionStorage.setItem('registroEmpresa', JSON.stringify(this.trialAccount));


        if(window.gtag) {
          window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'G-V2D4Z1PD6G' });
          window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'UA-93012005-1' });
          window.gtag('event','lead-use-grátis', { event_category: 'use-grátis', send_to: 'AW-873758741' });
  
          window.gtag('event','sign_up', { method: 'Empresa', send_to: 'G-V2D4Z1PD6G' })
          window.gtag('event','sign_up', { method: 'Empresa', send_to: 'AW-873758741' })
          window.gtag('event','sign_up', { method: 'Empresa', send_to: 'UA-93012005-1' })
        }
        
        if(window.fbq)
          window.fbq('track', 'Lead', {content_category: "Lead", content_name: "Company", value: this.nomeEmpresa});

        data['email'] = this.empresaEmail;

        this.$emit('success', data)

      })
      .catch((err:any) => {
        console.log(err)
        if(err?.message == "RECAPTCHA_NOT_LOADED") {
          
        }
        this.$emit('error', err)
        if (err?.response?.data?.detail) {
          if ((<string> err.response.data.detail).includes('E-mail já cadastrado')) {
            this.showEmailField = true;
          }
        }
      });
  }
}
