import { helpers } from 'vuelidate/lib/validators';
import freeEmailServices from '@/core/models/freeEmailServices';

const freeMailDomains = Object.keys(freeEmailServices);

export default (value: string) => {
	if (!helpers.req(value)) {
		return true;
	}
	if (!value.includes('@')) {
		return false;
	}
	const domain = value.split('@')[1];

	return !freeMailDomains.includes(domain);
};
