



























import { Component, Vue } from 'vue-property-decorator';
import TentativaRegistro from './section-funcionalidades-jobecam/TentativaRegistro.vue';

const VueScrollTo = require('vue-scrollto');

@Component({
  components: {
    TentativaRegistro
  },
})
export default class SectionRecrutamento extends Vue {
  public options!: any;
  public onTop: boolean = true;

  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public created() {
    this.handleScroll
    window.addEventListener("scroll", this.handleScroll);
  }
  public destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  public handleScroll() {
    this.onTop = window.scrollY <= 699
  }
  
  public goCadastro() {
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }

  public openModal() {
    // this.$emit('diagnostico-show',true);
    if(this.$i18n.locale=='pt-BR'){
       window.open('https://forms.gle/JM3Xj3s3epnw4YFb8', '_blank');
    }else{
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSewV3WLnjf8-OyNCLwn6EBVkZr_aDwPyxNWZmeJy1ZudDg1WA/viewform', '_blank');
    }
  }

  public done() {
    this.$bvModal.hide('modal-recrutamento-pdf')
  }
}
