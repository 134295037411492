
























































import { Component, Vue, Prop } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import Header from '@/components/layouts/Header.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import TentativaRegistroService from '@/core/Services/Empresa/TentativaRegistro';

import { Validate } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import notFreeMail from '@/core/validations/notFreeMail';
import { EmpresaTrialAccount } from '@/core/models/Entities';

const VueScrollTo = require('vue-scrollto');

@Component({
  components: {
    TextInput,
    CheckboxInput,
    TelInput,
  },
})
export default class TentativaRegistro extends Vue {
  @Validate({ required })
  private responsavel: string = '';

  @Validate({ required, email, notFreeMail })
  private empresaEmail: string = '';

  @Validate({ required })
  private empresaTelefone: string = '';

  @Validate({ checked: sameAs( () => true ) })
  private aceitoTermos: boolean = false;

  @Prop({default: false})
  private ebook!: boolean;

  private trialAccount!: EmpresaTrialAccount;

  private tentativaCadastroService = new TentativaRegistroService();

  public submitForm() {
    this.$v.$touch();

    if (this.$v.$invalid)
      return;

    this.trialAccount = {
      nomeEmpresa: this.responsavel,
      email: this.empresaEmail,
      telefone: this.empresaTelefone,
      nomeResponsavel: this.responsavel,
      lang: this.$i18n.locale,
      ebook: this.ebook
    };

    sessionStorage.setItem('registroEmpresa', JSON.stringify(this.trialAccount));

    this.tentativaCadastroService.create(this.trialAccount)
      .then((data: any) => {
        if(!this.ebook) {
          this.$router.push({ name: 'CadastroDadosDeAcesso', query: {lang: this.$i18n.locale} });
        } else {
          // this.$alert.addMsg(""+this.$t("Obrigada pelo interesse, em breve você receberá o Guia sobre Diversidade no seu e-mail"));
          // this.$alert.callBoxCheck(this.$t('OK').toString());
          if(this.$i18n.locale=='pt-BR'){
            window.location.href = 'https://forms.gle/JM3Xj3s3epnw4YFb8';
          }else{
            window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSfeS9urup3enCx7JEPpFbUfU66Tw8Y-zKzUkNwIP4X2t6oSQw/viewform';
          }
        }
        this.$emit('done')
      })
      .catch((err) => {
        console.log(err)
        this.$emit('done')
      });
  }
}
