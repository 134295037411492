













import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class CardHighlight extends Vue {
  @Prop({default: ""}) public img!: string;
  @Prop({default: ""}) public title!: string;
  @Prop({default: ""}) public content!: string;
  @Prop({default: "light"}) public bgVariant!: string;
  @Prop({default: "grey"}) public textVariant!: string;

}
