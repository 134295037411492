// tslint:disable
import ApiRouter from "@/core/apirouting/EndpointRouterInterface";
import Repository from "../Repository";


export default class AccountCofirmation extends Repository {

    constructor(i18n?: any) {
      super(new ApiRouter({
          create: { url: "/v1/c-a-confirmation", method: "POST", headers: {} },
        }));    
  }

}
