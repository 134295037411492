













































































import { Component, Vue, Prop } from 'vue-property-decorator';
import SocialMedia from '@/views/candidato/components/SocialMedia.vue';
import LangSelector from '@/components/LangSelector.vue';
import PageRedirect from '@/views/redirect/Redirect.vue';
import {siteCompanyEndPoint,siteCandidateEndPoint} from '@/core/endpoint';

@Component({
  components: {
    SocialMedia,
    LangSelector,
  },
})
export default class Header extends Vue {

  @Prop({default: false})
  public companyPage!: boolean;
  
  public empresaURL = siteCompanyEndPoint;
  public candidatoURL = siteCandidateEndPoint;

  private get inEmpresaPage() {
    return (this.companyPage || (this.$route.name+"").indexOf('HomeEmpresa') > -1);
  }

  public loginEmpresa(){
    let sUrl = '';
    
    if(this.inEmpresaPage){
      sUrl = this.empresaURL+'?lang='+this.$i18n.locale;
    }else{
      sUrl = this.candidatoURL+'?lang='+this.$i18n.locale;
    }
    window.location.replace(sUrl);
  }
  
  public openModal() {
    // this.$emit('diagnostico-show',true);
    if(this.$i18n.locale=='pt-BR'){
       window.open('https://forms.gle/JM3Xj3s3epnw4YFb8', '_blank');
    }else{
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSewV3WLnjf8-OyNCLwn6EBVkZr_aDwPyxNWZmeJy1ZudDg1WA/viewform', '_blank');
    }
  }

  public goHelp() {
    
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/1`;
    window.open(fc, '_blank');

    // if(this.toggleClickChat && window.jivo_destroy) {
    //   /* console.log('destroy') */
    //   this.toggleClickChat = false;
    //   window.jivo_destroy();
    // } else {
    //   /* console.log('open') */
    //   this.openChat()
    // }
  }
}
