// tslint:disable:no-string-literal
import ApiRouter from "../../apirouting/EndpointRouterInterface";
import Repository from "../Repository";

export default class JBCPayPal extends Repository {

  constructor() {
    super(new ApiRouter({
        create: { url: "/v1/jbcpaypal", method: "POST", headers: {} },
        fetch: { url: "/v1/jbcpaypal/{id}", method: "GET", headers: {} },
        fetchAll: { url: "/v1/jbcpaypal", method: "GET", headers: {} },
        update: { url: "/v1/jbcpaypal/{id}", method: "PUT", headers: {} }}));
  }

}
