



















import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class DuvidasCollapse extends Vue {
  @Prop({default: ""}) public question!: string;
  @Prop({default: ""}) public answer!: string;

  public showCollapse: boolean = false;
}
