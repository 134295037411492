


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IconCard extends Vue {
  @Prop() private iconClass!: string;
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
}
