





























import { Component, Prop, Vue } from "vue-property-decorator";
const VueScrollTo = require('vue-scrollto');

@Component
export default class SectionASelecao extends Vue {
  public options!: any;

  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public goCadastro() {
    this.$bvModal.show('modal-video-tour')
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }

  public openModalCadastro() {
    this.$emit('registryClicked',true)
    this.$bvModal.show('cadastro-empresa-modal')
  }
}
