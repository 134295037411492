























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import { siteCompanyEndPoint } from '@/core/endpoint';
import FormMonteSeuPlano from '@/views/empresa/cadastro/FormMonteSeuPlano.vue';

@Component({
  components: {
    'app-section': Section,
    FormMonteSeuPlano,
    PlanCard,
  },
})
export default class SectionPlanos extends Vue {
   private EPeriodoPlano_Mensal: EPeriodoPlano = EPeriodoPlano.Mensal;
  private EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;
  private Plano_Full: string = PlanoCredito.FULL;
  private Plano_Partial: string = PlanoCredito.PARTIAL;

  public tipoPlano: EPeriodoPlano = EPeriodoPlano.Anual;
  public grupoPlano: string = PlanoCredito.FULL;
  public loading: boolean = false;
  private planoCreditosService = new PlanoCredito();
  private planoCreditosList: PlanoCreditoEntity[] = [];

  private coresPlanos = [
    'dark-gray',
    'blue',
    'marine',
    'green',
  ];

  private get periodoJobcoins() {
    return this.tipoPlano === EPeriodoPlano.Mensal ? '' : 'Mensais';
  }

  @Watch('tipoPlano')
  public tipoPlanoChanged() {
    this.loadPlanos();
  }
 
 @Watch('grupoPlano')
  public tipoGrupoChanged() {
    this.loadPlanos();
  }

  public async created() {
    const planoId = sessionStorage.getItem('planoId');

    if (planoId && sessionStorage.getItem('access_token')) {
      const planoEscolhido: PlanoCreditoEntity = await this.planoCreditosService.fetch(+planoId);

      this.tipoPlano = planoEscolhido.period!;
    }

    this.loadPlanos();
  }

  public async loadPlanos() {
    this.planoCreditosList = [];
    this.loading = true;
    const data: { planoCreditos: PlanoCreditoEntity[] } = await this.planoCreditosService.fetchAll({ p: this.tipoPlano, g: this.grupoPlano });
    this.loading = false;
    this.planoCreditosList = data.planoCreditos;
  }

  public planoSelecionado(planoId: number, free: boolean) {
    sessionStorage.setItem('planoId', planoId.toString());

    const access_token = sessionStorage.getItem('access_token');

    if(access_token) {
        let frontBase = siteCompanyEndPoint;
        if(free == true){
          if (sessionStorage.getItem("access_token")) {
            window.location.replace(
              `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
                "access_token"
              )}&redirectTo=/`
            );
          }
        }else{
          const pid = this.planoCreditosList.find((el: PlanoCreditoEntity) => {return el.id == planoId})?.plan_identifier;
          this.$router.push({ name: 'CadastroPagamento', query: {lang: this.$i18n.locale, pid} });            
        }

        // this.$router.push({ name: 'CadastroPagamento', query: {lang: this.$i18n.locale} });
    } else {
        const pid = this.planoCreditosList.find((el: PlanoCreditoEntity) => {return el.id == planoId})?.plan_identifier;
        this.$router.push({ name: 'CadastroDadosDeAcesso', query: {lang: this.$i18n.locale, pid} });
    }

  }
}
