// tslint:disable:no-string-literal
import ApiRouter from '../../apirouting/EndpointRouterInterface';
import ClientAuthentication from '../ClientAuthentication';
import Repository from '../Repository';
import { CB } from '@/core/util';


export default class Contato extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/contato', method: 'POST', headers: {} },
                fetch: { url: '/v1/contato/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/contato', method: 'GET', headers: {} }}));
    }

    public call(key: string, data: any, ctype: string): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            (new ClientAuthentication()).auth().then((rtoken: any) => {
                const at = sessionStorage.getItem('access_token');
                sessionStorage.removeItem('access_token');
                const dta: any = {
                    rtoken,
                    ...data
                };
                const cdta = CB.genC(dta);
                super.call(key, cdta, ctype).then(resolve).catch(reject);
                if(at)
                    sessionStorage.setItem('access_token',at);
            }).catch((resp) => {
                reject(resp);
            });
        });
    }
}
