




















import { Component, Vue, Watch } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import SectionPlanos from '@/views/empresa/home/sections/SectionPlanos.vue'
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import FormMonteSeuPlano from '@/views/empresa/cadastro/FormMonteSeuPlano.vue';

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    PlanCard,
    FormMonteSeuPlano,
    SectionPlanos
  },
})
export default class CadastroEscolherPlano extends Vue {
  private EPeriodoPlano_Mensal: EPeriodoPlano = EPeriodoPlano.Mensal;
  private EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;

  private tipoPlano: EPeriodoPlano = EPeriodoPlano.Anual;
 
}
