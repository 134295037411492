












import { Component, Vue } from 'vue-property-decorator';
import SocialMedia from '@/views/candidato/components/SocialMedia.vue';

@Component({
  components: {
    SocialMedia,
  },
})
export default class LangSelector extends Vue {

  private get currentLang() {
    const currLocale = this.$i18n.locale.substring(0, 2);
    const lang = this.langs.find(lang => lang.locale.substring(0, 2) === currLocale);

    if (lang) {
      return lang.name;
    }

    return 'PT';
  }

  private langs = [
    {
      locale: 'pt-BR',
      name: 'PT'
    },
    {
      locale: 'es',
      name: 'ES'
    },
    {
      locale: 'en-US',
      name: 'EN'
    }
  ];

  get candidatoPage() {
    return this.$router.currentRoute.name!.indexOf('HomeCandidato') > -1 ? '/candidato' : ''
  }

  public setLocale(locale: string) {
    if (this.$i18n.locale === locale) {
      return;
    }
    this.$i18n.locale = locale;
    const localeRoute: string = locale.substring(0,2); 
    if(this.$router.currentRoute.name!.indexOf('HomeCandidato') > -1){
      if(locale.indexOf('pt') == -1)
        this.$router.replace({path:  '/candidato/'+localeRoute, query: { lang: locale } })
      else
        this.$router.replace({path:  '/candidato', query: { lang: locale } })
    } else {
      if(locale.indexOf('pt') == -1)
        this.$router.replace({path:  '/'+localeRoute, query: { lang: locale } })
      else
        this.$router.replace({path:  '/', query: { lang: locale } })
    }
  }
}
