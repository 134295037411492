















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class IconCard extends Vue {
  @Prop() private text!: string;
  @Prop() private imgSrc!: string;
  @Prop() private imgAlt!: string;
  @Prop() private textColor!: string;
  @Prop() private bgColor!: string;

  @Prop({ default: 'top' }) private align!: string;

  private get style() {
    let style = '';

    if (this.textColor) {
      style += `color: ${this.textColor}; `;
    }

    if (this.bgColor) {
      style += `background-color: ${this.bgColor};`;
    }

    return style;
  }
}
