import { Method } from 'axios';

// tslint:disable:no-string-literal

interface IEndPointStructureHeaders {
  [key: string]: string;
  Authorization: string;
  'Content-Type': string;
  'Accept': string;
}


interface IEndPointStructureObject {
  [key: string]: string | object;
  url: string;
  method: Method;
  headers: IEndPointStructureHeaders;
  grantType: string;
}

export default class ApiRouter {
  [key: string]: IEndPointStructureObject;
  public create: IEndPointStructureObject;
  public fetch: IEndPointStructureObject ;
  public fetchAll: IEndPointStructureObject;
  public fetchByToken: IEndPointStructureObject ;
  public update: IEndPointStructureObject;
  public delete: IEndPointStructureObject;
  public me: IEndPointStructureObject;
  public auth: IEndPointStructureObject;
  public info: IEndPointStructureObject;

  constructor(mounted: any) {
    this.create = mounted['create'];
    this.fetch = mounted['fetch'];
    this.fetchAll = mounted['fetchAll'];
    this.fetchByToken = mounted['fetchByToken'];
    this.update = mounted['update'];
    this.delete = mounted['delete'];
    this.me = mounted['me'];
    this.auth = mounted['auth'];
    this.info = mounted['info'];
  }

}
