

























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import TextArea from '@/components/inputs/TextArea.vue';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";

import ContatoService from '@/core/Services/Basic/Contato';
import { EmpresaTrialAccount } from '@/core/models/Entities';
import Alert from '@/components/alerts/Alert.vue';
import TentativaRegistroService from '@/core/Services/Empresa/TentativaRegistro';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';
import FormPlanoCustomizado from './FormPlanoCustomizado.vue';

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    PlanCard,
    TextArea,
    TextInput,
    TelInput,
    FormPlanoCustomizado,
  },
})
export default class CadastroPlanoCustomizado extends Vue {
}
