























































import Vue from 'vue';
import Component from 'vue-class-component';
import Section from '@/components/layouts/Section.vue';
import IconCard from '@/components/IconCard.vue';
import { siteCandidateEndPoint, siteCompanyEndPoint } from "@/core/endpoint";

@Component({
  components: {
    Section,
    IconCard,
  },
})
export default class PorqueJobecam extends Vue {

  public goRegister() {
    const frontBase = siteCandidateEndPoint;
    window.location.href = `${frontBase}/register?lang=${this.$i18n.locale}`;
  }
}
