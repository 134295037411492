import Vue from 'vue';
import Vuex, { StoreOptions, ModuleTree } from 'vuex';
import { profile } from '@/store/profile/index';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      profile,
    },
    strict: process.env.NODE_ENV !== 'production',
});
