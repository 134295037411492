
























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class TextArea extends Vue {
  @Prop({default: ""}) public label!: String;
  @Prop({default: ""}) public value!: String;
  @Prop({default: ""}) public required!: boolean;
  @Prop({default: ""}) public placeholder!: String;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
