import ApiRouter from "@/core/apirouting/EndpointRouterInterface";
import { clientId } from "../../endpoint";
import { CandidatoEntity, PerfilCandidatoEntity } from "../../models/Entities";
import ClientAuthentication from "@/core/Services/ClientAuthentication";
import Repository from "@/core/Services/Repository";
import { CB } from '@/core/util';

export default class Candidato extends Repository {
  public entidade: CandidatoEntity;

  constructor(i18n?: any) {
    super(
      new ApiRouter({
        auth: { url: "/v1/oauth", method: "POST", headers: {} },
        create: { url: "/v1/candidato", method: "POST", headers: {} },
        fetch: { url: "/v1/candidato/{id}", method: "GET", headers: {} },
        delete: { url: "/v1/candidato/{id}", method: "DELETE", headers: {} },
        fetchAll: { url: "/v1/candidato", method: "GET", headers: {} },
        info: { url: "/v1/my-info", method: "GET", headers: {} },
        me: { url: "/v1/me", method: "GET", headers: {} },
        logout: { url: "/v1/me", method: "DELETE", headers: {} },
        update: { url: "/v1/candidato/{id}", method: "PUT", headers: {} }
      })
    );
    if (i18n) {
      this.$i18n = i18n;
    }
    this.entidade = new CandidatoEntity();
  }

  public create(data: any, ctype?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
          reject(resp);
      });
    });
  }

  public loadEntidade(promisse: Promise<Candidato>, callback?: () => void) {
    promisse
      .then((data: any) => {
        this.entidade = data;
        if (callback) {
          callback();
        }
      })
      .catch((err: any) => {
        // tslint:disable-next-line:no-console
        console.log("Couldn't load my info.", err);
      });
  }

  public loadFromNomeUrl(url: string, callback?: () => void): void {
    if (sessionStorage.getItem("access_token") !== null) {
      this.loadEntidade(
        super.call("fetch", { id: 0, urlCode: url }, "application/json"),
        callback
      );
    } else {
      this.publicLoadFromNomeUrl(url, callback);
    }
  }

  public publicLoadFromNomeUrl(url: string, callback?: () => void): void {
    new ClientAuthentication()
      .auth()
      .then((identity: any) => {
        if (
          identity.access_token === null ||
          identity.access_token === undefined
        ) {
          throw new Error("Token de acesso inválido");
        }

        const token = identity.token_type + " " + identity.access_token;
        this.endPointAPI.fetch.headers.Authorization = token;
        this.loadEntidade(
          super.call("fetch", { id: 0, urlCode: url }, "application/json"),
          callback
        );
      })
      .catch(resp => {
        // tslint:disable-next-line:no-console
        console.log(resp);
      });
  }

  public whatIsMissing(): string[] {
    let msg: string[] = [];
    const perfil: PerfilCandidatoEntity = this.entidade.perfil!;
    if (perfil) {
      // tslint:disable-next-line:no-console
      console.log(this.$i18n.locale);
      if (!perfil.areaAtuacao) msg.push(this.$i18n.t("Área de Atuação"));

      if (!perfil.profissao2) msg.push(this.$i18n.t("Profissão"));

      if (!perfil.escolaridade) msg.push(this.$i18n.t("Escolaridade"));

      if (!perfil.telefone) msg.push(this.$i18n.t("Telefone"));

      if (!perfil.cep) msg.push(this.$i18n.t("CEP"));

      if (!perfil.rua) msg.push(this.$i18n.t("Rua"));

      if (!perfil.numero) msg.push(this.$i18n.t("Número"));

      if (!perfil.bairro) msg.push(this.$i18n.t("Bairro"));

      if (!perfil.dataNasc) msg.push(this.$i18n.t("Data Nascimento"));
    }
    return msg;
  }

  public isRegistryCompleted(): boolean {
    if (
      this.entidade.perfil &&
      this.entidade.perfil.cadastroConcluido !== "SIM"
    )
      return false;

    return true;
  }

  public checkIfRegistryIsCompleted(): boolean {
    if (!this.isRegistryCompleted()) {
      this.openAlert(
        this.whatIsMissing(),
        this.$i18n
          .t("Falta pouco! Termine seu cadastro para continuar")
          .toString()
      );
      this.getRouter().push({
        path: "/edit",
        query: { lang: this.getI18n().locale }
      });
      return false;
    }

    return true;
  }

  public loadMyInfo(callback?: () => void): void {
    super
      .myInfo()
      .then((data: any) => {
        this.entidade = data.entity;
        this.getI18n().locale =
          this.entidade.perfil!.idioma!.idioma ||
          this.entidade.lingua ||
          "pt-BR";
        if (callback) {
          callback();
        }
      })
      .catch((err: any) => {
        // tslint:disable-next-line:no-console
        console.log("Couldn't load my info.", err);
      });
  }

  public auth(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = "password";

    return super.auth(data);
  }

  public logout(): Promise<any> {
    return super.call("logout", { id: 0 }, "application/json").then(() => {
      this.pushToLogout();
    });
  }

  public async save(): Promise<any> {
    this.cleanObject();

    return super
      .update(this.entidade)
      .then((data: any) => {
        this.entidade = data;
        return true;
      })
      .catch((err: any) => {
        return false;
      });
  }

  public saveProfileField(fieldName: string, value: any, candidatoId?: number) {
    const data: any = {};
    data[fieldName] = value;

    candidatoId = candidatoId || this.entidade!.id;

    return super.update({ id: candidatoId, perfilcandidato: data });
  }

  public saveField(fieldName: string, value: any, candidatoId?: number) {
    const data: any = {};
    data.id = candidatoId || this.entidade!.id;
    data[fieldName] = value;
    return super.update(data);
  }

  public saveBackgroundAvatar(file: any): Promise<any> {
    return this.saveFile("bgavatar", file);
  }

  public saveAvatar(file: any): Promise<any> {
    return this.saveFile("avatar", file);
  }

  public saveCurriculum(file: any): Promise<any> {
    return this.saveFile("curriculumVitae", file);
  }

  public saveFile(key: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append("id", "" + this.entidade.id);
    formData.append(key, file);
    return super.update(formData, "multipart/form-data");
  }

  private cleanObject() {
    this.entidade.perfilcandidato = this.entidade.perfil;
    this.entidade.perfilcandidato!.idiomaId = this.entidade.perfil!.idioma!.id;
  }
}
