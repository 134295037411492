






























import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import Header from '@/components/layouts/Header.vue';
import SectionHeader from './sections/SectionHeader.vue';
import FormCadastro from './FormCadastro.vue';
import FormConfirmation from '@/views/empresa/cadastro/FormConfirmation.vue';
import { EmpresaEntity, EmpresaTrialAccount, PlanoCreditoEntity } from '@/core/models/Entities';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    'app-section': Section,
    'app-header': Header,
    SectionHeader,
    Alert,
    FormCadastro,
    FormConfirmation
  },
})
export default class CadastroDadosDeAcessoPlural extends Vue {
  public promise: Promise<any> |null = null;
  private alert: Alert = new Alert();

  private showEmailField = false;
  public  planoId: number|null = null;

  public step: number = 0;

  public empresa: EmpresaEntity|null = null;

  public async created() {
    
  }



  public success(data: EmpresaEntity) {
    this.empresa = data;
    this.step++;
    // this.$emit('success',data)
  }

  public successConfirmation(data: any) {
    let frontBase = siteCompanyEndPoint;
    const planoId = sessionStorage.getItem('planoId');
    
    if(window.gtag) {
      window.gtag('event','sign_up', { method: 'Empresa', event_category: 'Plural', event_label: 'Cadastro em Plural', send_to: 'G-V2D4Z1PD6G' })
      window.gtag('event','sign_up', { method: 'Empresa', event_category: 'Plural', event_label: 'Cadastro em Plural', send_to: 'AW-873758741' })
      window.gtag('event','sign_up', { method: 'Empresa', event_category: 'Plural', event_label: 'Cadastro em Plural', send_to: 'UA-93012005-1' })
    }

    if(window.fbq)
      window.fbq('track', 'AddToCart');

    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
        `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
          "access_token"
        )}&redirectTo=/plural&lang=${this.$i18n.locale}`
      );
    }
  }

  public error() {
    console.log('err')
  }

}
