import { EPeriodoPlano } from '../Enums/EPeriodoPlano';

// tslint:disable
export class CandidatoEntity {
  id?:number;
  idMD5: string|undefined;
  primeironome: string|undefined;
  sobrenome: string|undefined;
  email: string|undefined;
  nomeUrl: string|undefined;
  userFacebook: string|undefined;
  userLinkedin: string|undefined;
  newsletter: string|undefined;
  status: string|undefined;
  sexo: string|undefined;
  idRacaCor: string|undefined;
  lingua: string|undefined;
  dataCadastro: string|undefined;
  ipcadastro: string|undefined;
  dataAlteracao: string|undefined;
  ipAlteracao: string|undefined;
  relativePath: string|undefined;
  fotoAvatar: string|undefined;
  backgroundAvatar: string|undefined;
  cpf: string|undefined;
  perfil: PerfilCandidatoEntity;// Filled on fetch
  perfilcandidato: PerfilCandidatoEntity;// Filled for submit
  completionMissing?: any;
  completionPercentage?: number;

  constructor() {
    this.newsletter = "Y";
    this.perfil = new PerfilCandidatoEntity();
    this.perfilcandidato = new PerfilCandidatoEntity();
  }

}
export class PerfilCandidatoEntity {
  id?:number;
  apresentacao: string|undefined;
  profissao: string|undefined;
  localidade: string|undefined;
  curriculum: string|undefined;
  videoapresentacao: string|undefined;
  idioma: IdiomaEntity|undefined;
  plano: string|undefined;
  areaAtuacao: any|undefined;
  escolaridade: any|undefined;
  dataNasc: string|undefined;
  cep: string|undefined;
  rua: string|undefined;
  numero: string|undefined;
  bairro: string|undefined;
  cidade: string|undefined;
  estado: string|undefined;
  pais: string|undefined;
  telefone: string|undefined;
  cadastroConcluido: string|undefined;
  profissao2: string|undefined;
  dataCancela: string|undefined;
  dataAdesao: string|undefined;
  dataFinal: string|undefined;
  experiencias: Array<ExperienciaEntity>|undefined;
  formacoes: Array<FormacaoEntity>|undefined;
  idiomas: Array<IdiomasCandidatoEntity>|undefined;
  cidadeId: any|null;
  estadoId: any|null;
  areaatuacaoId: number|undefined;
  escolaridadeId: number|undefined;
  nomeEstado: string|undefined;
  nomeCidade: string|undefined;
  thumbVideo: string|undefined;
  videoapresentacaoMP4: string|undefined;
  recordObject?: string;
  recordId?: string;
  idiomaId?: number;
  linkFacebook?: string;
  linkLinkedin?: string;
  linkInstagram?: string;
  geoJson?: string;

  constructor() {
    this.idioma = new IdiomaEntity();
    this.idiomas = [];
    this.formacoes = [];
    this.experiencias = [];
  }

}
export class IdiomasCandidatoEntity {
  id?:number;
  nivel:string|undefined;
  idiomaId:any|null;
  idioma: IdiomaEntity|undefined;
}
export class IdiomaEntity {
  id?:number;
  idioma: string|undefined;
  legenda: any|undefined;
}
export class PlanoCandidatoEntity {
  id?:number;
  plano: string|undefined;
  preco: string|undefined;
  periodo: string|undefined;
  tempoVideocv: string|undefined;
  compartilhaVideocv: string|undefined;
  quantidadeCandidaturas: string|undefined;
  destaquePerfil: string|undefined;
  quantidadeMensagens: string|undefined;
  popular: string|undefined;
  free: string|undefined;
  mostrar: any|undefined;
}
export class AreaAtuacaoEntity {
  id?:number;
  descricao: any|undefined;
}
export class NivelEscolaridadeEntity {
  id?:number;
  descricao: string|undefined;
  ordenar: any|undefined;
}
export class PaisEntity {
  id?:number;
  nome: string|undefined;
  sigla: string|undefined;
  idioma: any|undefined;
}
export class EstadoEntity {
  id?:number;
  ufNome: string|undefined;
  ufAbrev: string|undefined;
  pais: any|undefined;
}
export class CidadeEntity {
  id?:number;
  cidNome: string|undefined;
  cidCod: string|undefined;
  uf: any|undefined;
  ufId?: number|undefined;
  ufAbrev?: string|undefined;
  completoNome?: string|undefined;
}
export class FormacaoEntity {
  id?:number;
  nome: string|undefined;
  dataEntrada: string|undefined;
  dataSaida: string|undefined;
  atividade: string|undefined;
  localidade: string|undefined;
  descricaoAtividade: string|undefined;
  atual: string|undefined;
  perfilCandidato: string|undefined;
  mesInicio: string|undefined;
  anoInicio: string|undefined;
  mesFim: string|undefined;
  anoFim: string|undefined;
  total: any|undefined;
}
export class ExperienciaEntity {
  id?:number;
  nome: string|undefined;
  dataEntrada: string|undefined;
  dataSaida: string|undefined;
  atividade: string|undefined;
  localidade: string|undefined;
  descricaoAtividade: string|undefined;
  atual: string|undefined;
  perfilCandidato: string|undefined;
  mesInicio: string|undefined;
  anoInicio: string|undefined;
  mesFim: string|undefined;
  anoFim: string|undefined;
  total: any|undefined;
}
export class VagaEntity {
  id?:number;
  nomeUrl: string|undefined;
  posicao: string|undefined;
  salario: string|undefined;
  descricao: string|undefined;
  nivel: string|undefined;
  dataPublicacao: string|undefined;
  dataCadastro: string|undefined;
  dataAtualiza: string|undefined;
  dataEncerra: string|undefined;
  status: string|undefined;
  localidade: string|undefined;
  requisitos: string|undefined;
  competencias: string|undefined;
  confidencial: string|undefined;
  restrita: string|undefined;
  transcricao: number|undefined;
  perguntas: Array<VagaPerguntaEntity>;
  candidaturas?: Array<any>|undefined;
  empresa?: EmpresaEntity|undefined;
  historicoCredito?: any|undefined;
  cidade?: CidadeEntity|undefined;
  cidadeId?: number|undefined;
  isCandidatado?: boolean;
  isPendente?: boolean;
  blind: number;

  constructor() {
    this.perguntas = [];
    this.salario = "0";
    this.blind = 0;
  }
}
export class VagaPerguntaEntity {
  id: number = 0;
  numeroPergunta!: number;
  pergunta: string|undefined;
  tempo: number = 1;
  datacadastro: any|undefined;
  idioma: string|undefined;

}

export class TagTranscricaoEntity {
  id!: number;
  tag!: string;
  vaga?: VagaEntity;
  idvaga?: number;
  tags!: string;
}

export class VagaVideoRespostaEntity {
  pergunta?:VagaPerguntaEntity;
  candidato?:CandidatoEntity;
  candidatura?:CandidaturaEntity;
  video!:string;
  status!:string;
  pontostranscricao?:number;
  recordObject?: string;
  recordId?: string;
}

export class RecrutadorEntity {
  id?:number;
  nome: string|undefined;
  email: string|undefined;
  fotoAvatar: string|undefined;
  nomeUrl: string|undefined;
  cargo: string|undefined;
  status: string|undefined;
  sexo: any|undefined;
  permissaoAdm: boolean|undefined;
}

export class EmpresaEntity {
  id?:number;
  primeironome: string|undefined;
  sobrenome: string|undefined;
  email: string|undefined;
  nomeUrl: string|undefined;
  fotoAvatar: string|undefined;
  backgroundAvatar: string|undefined;
  status: string|undefined;
  sexo: string|undefined;
  lingua: string|undefined;
  relativePath: string|undefined;
  statusCadastro: string|undefined;
  statusPagamento: string|undefined;
  perfil: PerfilEmpresaEntity|undefined;
  perfilempresa: PerfilEmpresaEntity|undefined;
  nomeEmpresa?: string;
  cnpj?: string;
  // TODO: Implement Entity Match in Here
  matchCandidate?: any;
  senha?: string;
  plano_id?: string;

  constructor() {
    this.perfil = new PerfilEmpresaEntity();
    this.perfilempresa = new PerfilEmpresaEntity();
  }
}
export class PerfilEmpresaEntity {
  id?:number;
  nomeEmpresa: string|undefined;
  razaosocial: string|undefined;
  endereco: string|undefined;
  numero: string|undefined;
  telefones: string|undefined;
  nomeCidade: string|undefined;
  bairro: string|undefined;
  uf: string|undefined;
  pais: string|undefined;
  logoempresa: string|undefined;
  descricao: string|undefined;
  especializacao: string|undefined;
  setor: string|undefined;
  tamanho: string|undefined;
  fundada: string|undefined;
  tipo: string|undefined;
  site: string|undefined;
  complemento: string|undefined;
  cep: string|undefined;
  cidade?: any|null;
  cidadeId?: any|null;
  ufId?: any|null;
  cnpj?: string|null;
  videoApresentacao?: string|null;
}
export class CandidaturaEntity {
  id?:number;
  vaga: VagaEntity;
  candidato: CandidatoEntity;
  status: string|undefined;
  comentarioRecrutador: string|undefined;
  ratingRecrutador: number|undefined;
  comentarioGestor: string|undefined;
  ratingGestor: number|undefined;
  organica: string|undefined;
  dataCadastro: string|undefined;
  dataAtualiza: string|undefined;
  idvaga: string|undefined;
  idcandidato: any|undefined;
  videosRespostas?: Array<VagaVideoRespostaEntity>;
  score?:number;
  avgRating?: number;

  constructor() {
    this.candidato = new CandidatoEntity();
    this.vaga = new VagaEntity();
  }

  get classeStatus() {
    switch(this.status){
      case 'VIDEOSPENDENTES':
        return 'bg-warning';
      case 'EMANALISE':
        return 'bg-info';
      case 'APROVADO':
        return 'bg-info';
      case 'REPROVADO':
        return 'bg-danger';
      case 'VIDEOSEMPROCESSAMENTO':
        return 'bg-secondary';
    }
    return 'bg-info';
  }

}
export class PlanoCreditoEntity {
  id: number|undefined;
  nome: string|undefined;
  credito_qtd: string|undefined;
  credito_adicional: string|undefined;
  tipo: string|undefined;
  preco?: number;
  recomendado?: number;
  free: boolean = false;
  period?: EPeriodoPlano;
  recomendationText: string|null = null;
  avaliableList: string|null = null;
  plan_identifier: string|null = null;
}
export class PagamentoCreditoEntity {
  id: number|undefined;
  valor: number|undefined;
  dataPagamento: string|undefined;
  dataCriacao: string|undefined;
  status: string|undefined;
  correlationid?:number;
  payerid?:number;
  token: string|undefined;
  transactionid?:number;
  expressCheckout: string|undefined;
  expressCheckoutDetails: string|undefined;
  recurringPaymentsProfile: string|undefined;
  candidato: CandidatoEntity|undefined;
  empresa: EmpresaEntity|undefined;
  planoCredito: PlanoCreditoEntity|undefined;
  moduloPagamentoId: number|undefined;
}

export class QuestionaryEntity {
  id: number|undefined;
  name: string|undefined;
  isAssessment: boolean|undefined;
  dateCreated: any|undefined;
  isOcai: boolean|undefined;
  questions: QuestionEntity[]|undefined;

  constructor(){
    this.questions = [];
  }

}
export class QuestionEntity{
  id: number|undefined;
  questionaryId: number|undefined;
  questionTypeId: number|undefined;
  questionary: QuestionaryEntity|undefined;
  questionType: any|undefined;
  answers: AnswerEntity[]|undefined;
  description: string|undefined;
  points: number|undefined;
  dateCreated: any|undefined;
  dimension: any|undefined;
  dimensionId: number|undefined;

  constructor(){
    this.answers = []
  }
}

export class AnswerEntity {
  id: number|undefined;
  description: string|undefined;
  dateCreated: any|undefined;
  correct: boolean|undefined;
  points: number|undefined;
  question: QuestionEntity|undefined;
  rate: number|null = null;
}

export class QuestionaryCandidatoEntity {
  id: number|undefined;
  answered: boolean|undefined;
  dateStart: any|undefined;
  dateEnd: any|undefined;
  candidato: CandidatoEntity|undefined;
  questionary: QuestionaryEntity|undefined;
  questionaryAnswerCandidato: QuestionaryAnswerCandidatoEntity[]|undefined;
  questionaryId: number|undefined;
  candidatoId: number|undefined;
  answers: any|undefined;

  constructor() {
    this.answers = { };
  }
}

export class QuestionaryEmpresaEntity {
  id: number|undefined;
  answered: boolean|undefined;
  dateStart: any|undefined;
  dateEnd: any|undefined;
  empresa: EmpresaEntity|undefined;
  questionary: QuestionaryEntity|undefined;
  questionaryAnswerEmpresa: QuestionaryAnswerEmpresaEntity[]|undefined;
  questionaryId: number|undefined;
  empresaId: number|undefined;
  answers: any|undefined;
  seguindo?: boolean;

  constructor() {
    this.answers = { };
  }
}

export class QCAnswerData {
  answerId!: number;
  rate: number|null = null;
  questionId:number = 0;

  constructor(answerId:number,rate:number){
    this.answerId = answerId;
    this.rate = rate;
  }
}

export class QuestionaryAnswerCandidatoEntity {
  id: number|undefined;
  rate: number;
  description: string|undefined;
  questionaryCandidato: QuestionaryCandidatoEntity|undefined;
  answer: AnswerEntity|undefined;
  questionaryCandidatoId: number|undefined;
  answerId: number|undefined;

  constructor(){
    this.rate = 0
  }
}

export class QuestionaryAnswerEmpresaEntity {
  id: number|undefined;
  rate: number;
  description: string|undefined;
  questionaryEmpresa: QuestionaryEmpresaEntity|undefined;
  answer: AnswerEntity|undefined;
  questionaryEmpresaId: number|undefined;
  answerId: number|undefined;

  constructor(){
    this.rate = 0
  }
}


// Payments

export class AssinaturaEntity {
  id: number|undefined;
  token: string|undefined;
  priceCents: number|undefined;
  currency: string|undefined;
  active: number|undefined;
  suspended: number|undefined;
  trial: number|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  planoCredito: PlanoCreditoEntity|undefined|null;
  createdAt: any|undefined; // date
  updatedAt: any|undefined|null;
  expiresAt: any|undefined|null;
  constructor(){
    this.planoCredito = new PlanoCreditoEntity()
  }
}

export class MetodoPagamentoEntity {
  id: number|undefined;
  token: string|undefined|null;
  description: string|undefined|null;
  itemType: string|undefined|null;
  holderName: string|undefined|null;
  displayNumber: string|undefined|null;
  brand: string|undefined|null;
  validationMonth: number|undefined|null;
  validationYear: number|undefined|null;
  tipo: number|undefined|null;
  default: number|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  createdAt: any|undefined|null;
}

export class FaturaEntity {
  id: number|undefined;
  token: string|undefined|null;
  currency: string|undefined|null;
  paidTaxesCents: number|undefined|null;
  totalPaidCents: number|undefined|null;
  totalCents: number|undefined|null;
  status: string|undefined|null;
  secureId: string|undefined|null;
  tipo: number|undefined|null;
  invoiceData: string|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  assinaturaPagamento: AssinaturaEntity|undefined|null;
  createdAt: any|undefined|null;
  paidAt: any|undefined|null;
  dataVencimento: any|undefined|null;
  constructor(){
    this.paidAt = {};
    this.empresa = new EmpresaEntity()
    this.dataVencimento = {};
    this.assinaturaPagamento = new AssinaturaEntity()
  }
}

export class EmpresaTrialAccount {
  nomeEmpresa: string|undefined;
  email: string|undefined;
  telefone: string|undefined;
  nomeResponsavel: string|undefined;
  lang: string|undefined;
  ebook: boolean|undefined;
}

export interface LinkedInUser {
  firstName: string;
  lastName: string;
  emailAddress: string;
}
