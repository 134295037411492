import { render, staticRenderFns } from "./SectionElesNosEscolheramClientes.vue?vue&type=template&id=0c2d843c&scoped=true&"
import script from "./SectionElesNosEscolheramClientes.vue?vue&type=script&lang=ts&"
export * from "./SectionElesNosEscolheramClientes.vue?vue&type=script&lang=ts&"
import style0 from "./SectionElesNosEscolheramClientes.vue?vue&type=style&index=0&id=0c2d843c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2d843c",
  null
  
)

export default component.exports