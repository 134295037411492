

















































import Vue from 'vue';
import Component from 'vue-class-component';
import MobileFeatureCard from '@/components/MobileFeatureCard.vue';

@Component({
  components: {
    MobileFeatureCard,
  },
})
export default class SmallFeatureCards extends Vue {
}
