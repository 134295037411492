
























import { Component, Prop, Vue } from "vue-property-decorator";
import Section from '../../Section.vue';
const VueScrollTo = require('vue-scrollto');
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    'app-section': Section,
    Carousel,
    Slide
  }
})
export default class SectionNaMidia extends Vue {
  public options!: any;
  public inMidia: any[] =[]
 
  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }


  get isMobile() {    
      return ( window.innerWidth <= 800 );
  }

  public created() {
    this.inMidia = [
      
      { 
        address: 'https://forbes.com.br/carreira/2022/03/startup-quer-aumentar-diversidade-com-entrevista-as-cegas/',
        img: 'https://storage.googleapis.com/jobecam-assets/NaM%C3%ADdia_Forbes_Sele%C3%A7%C3%A3o_An%C3%B4nima.webp',
        title: 'Startup quer aumentar diversidade com entrevista anônima'
      },
      { 
        address: 'https://g1.globo.com/rs/rio-grande-do-sul/noticia/2021/10/19/empresas-apostam-em-entrevista-as-cegas-para-evitar-preconceito-ao-contratar-candidatos-no-rs.ghtml',
        img: 'https://storage.googleapis.com/jobecam-assets/NaMidia_G1_Renner.webp',
        title: 'Empresas apostam em entrevista anônima para evitar preconceito'
      },
      { 
        address: 'https://exame.com/bussola/startup-cria-curriculo-sem-nome-foto-ou-idade-para-evitar-discriminacao/',
        img: 'https://storage.googleapis.com/jobecam-assets/NaM%C3%ADdia_Exame.webp',
        title: 'Startup cria currículo sem nome, foto ou idade para evitar discriminação'
      },
      { 
        address: 'https://forbes.com.br/forbes-mulher/2021/12/startup-faz-recrutamento-as-cegas-so-para-mulheres/',
        img: 'https://storage.googleapis.com/jobecam-assets/NaMidia_Forbes_Fhinck.webp',
        title: 'Startup faz recrutamento anônimo só para mulheres'
      },
      { 
        address: 'https://valor.globo.com/empresas/noticia/2019/09/18/recrutamento-as-cegas-visa-contratar-sem-preconceito.ghtml',
        img: 'https://storage.googleapis.com/jobecam-assets/valor_ec.webp',
        title: 'Recrutamento Anônimo visa contratar sem preconceito'
      },
      { 
        address: 'https://forbes.com.br/carreira/2018/11/recrutamento-as-cegas-aumenta-diversidade-nas-empresas/',
        img: 'https://storage.googleapis.com/jobecam-assets/forbes.webp',
        title: 'Recrutamento Anônimo aumenta a diversidade'
      },
      
    ];
  }

  public goCadastro() {
    this.$bvModal.show('modal-video-tour')
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }

  public openModalCadastro() {
    this.$emit('registryClicked',true)
    this.$bvModal.show('cadastro-empresa-modal')
  }
}
