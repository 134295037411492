



























import { Action, Getter, State } from 'vuex-class'; 
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormCadastro from '@/views/empresa/cadastro/FormCadastro.vue';
import FormConfirmation from '@/views/empresa/cadastro/FormConfirmation.vue';
import { EmpresaEntity } from '@/core/models/Entities';

@Component({
  components: {
    FormCadastro,
    FormConfirmation
  }
})
export default class CadastroEmpresaModal extends Vue {

  @Prop({default: null})
  public planid!: string|null;

  public step: number = 0;

  public empresa: EmpresaEntity|null = null;

  get subtitle(){
    switch(this.step){
      case 0:
        return 'Informe seus dados para iniciar';
      case 1:
        return 'Enviamos um e-mail com o código de confirmação da sua conta. Informe-o no campo abaixo';
    }
  }
    get title(){
    switch(this.step){
      case 0:
        return 'EXPERIMENTE GRÁTIS';
      case 1:
        return 'Confirmação de conta';
    }
  }

  public success(data: EmpresaEntity) {
    this.empresa = data;
    this.step++;
    // this.$emit('success',data)
  }

  public error(data: any) {
    this.$emit('error','registry')
  }

  public successConfirmation(data: any) {
    this.$emit('success',data)
  }
  public errorConfirmation() {
    this.$emit('error','confirmation')
  }
}
