

















































































































































import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EmpresaService from '@/core/Services/Empresa/Empresa';
import Service from '@/core/Services/Empresa/PerfilEmpresa';
import TelInput from '@/components/inputs/TelInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import { EmpresaEntity, EmpresaTrialAccount, PlanoCreditoEntity } from '@/core/models/Entities';
import { PasswordStrengh } from '@/core/util'
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import { Validate } from 'vuelidate-property-decorators';
import { required, sameAs, email, requiredIf } from 'vuelidate/lib/validators';
import notFreeMail from '@/core/validations/notFreeMail';
import Alert from '@/components/alerts/Alert.vue';
import PerfilEmpresa from '@/core/Services/Empresa/PerfilEmpresa';
import Empresa from '@/core/Services/Empresa/Empresa';

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    TextInput,
    CheckboxInput,
    TelInput,
    PlanCard,
    Alert,
  },
})
export default class CadastroDadosInvoice extends Vue {
  private empresaService = new EmpresaService();
  private empresa: EmpresaEntity = new EmpresaEntity();
  private alert: Alert = new Alert();
  public perfilEmpresa: PerfilEmpresa;
  private planoEscolhido: PlanoCreditoEntity|null = null;
  private planoCreditosService = new PlanoCredito();
  public promise: Promise<any>|null = null;
  public idemp: number=0;
  public estadoId: number|null = null;
  private Account: EmpresaEntity|null = null;
  
  constructor(){
    super();
    this.perfilEmpresa = new PerfilEmpresa();
  }


  public async created() {

    const dadosEmpresa = sessionStorage.getItem('dadosEmpresa');

    const planoId: number = parseInt(sessionStorage.getItem('planoId')+"");

    if(planoId && planoId > 0){
        await this.planoCreditosService.fetch(+planoId)
            .then((data: PlanoCreditoEntity) => {
                // console.log(data);
                this.planoEscolhido = data;
            })
            .catch((err: any) => {
                // console.log(err);
            });
    }

    if (dadosEmpresa){
      this.Account = JSON.parse(dadosEmpresa)
      this.idemp = this.Account!.id?this.Account!.id:0;
      await this.perfilEmpresa.loadProfile(this.idemp);
    }   
  }


  public submitForm(evt: any) {
    evt.preventDefault();
    this.promise = this.perfilEmpresa.save().then((valid: boolean) => {
      if(valid) {
        let frontBase = siteCompanyEndPoint;
        if (sessionStorage.getItem("access_token")) {
          window.location.replace(
            `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
              "access_token"
            )}&redirectTo=/profile?lang=${this.$i18n.locale}`
          );
        }
      }
    });


  }



}
