



















































import Vue from 'vue';
import Component from 'vue-class-component';
import FeatureCard from '@/components/FeatureCard.vue';

@Component({
  components: {
    FeatureCard,
  },
})
export default class LargeFeatureCards extends Vue {
}
