



























import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    'app-section': Section,
    Carousel,
    Slide
  },
})
export default class SectionElesNosEscolheramClientes extends Vue {
  public partners: any[] = []

  public mounted() {
    this.partners = [
      {
        key: "general",
        group: "Parceiros",
        path: "/static/parceiros/comerciais/",
        object: false,
        logos: [ 
          "gupy.png",
          "greenhouse.jpg",
          "OGSE_Logo_Wht_Red_RGB-01.png",
          "Microsoft-Logo-2012.jpg",
          "google-for-startups.jpg",
          "people_strategy.png",
          "estacao_hack.png",
          "UNWomen_WePrinciples_endt_support_k_pos_rgb.png",
        ]
      },
      {
        key: "comercials",
        group: "Parceiros Comerciais",
        path: "/static/parceiros/comerciais/",
        object: true,
        logos: [ 
          { text: "indeed.png", link: "https://www.indeed.com/" },
          { text: "recruta_simples.jpg", link: "https://www.recrutasimples.com.br/" },
          { text: "huddle.png", link: "https://www.huddlebrasil.com/" },
          { text: "rankdone.png", link: "https://rankdone.com/" }
        ]
      },
      {
        key: "ensino",
        group: "Instituições de Ensino Parceiras",
        path: "/static/parceiros/ensino/",
        object: false,
        logos: [
          "Logo_Anhembi_PNG.png",
          "FMU.png",
          "IBMRLogo.png",
          "logo-fadergs.png",
          "logo-fpb.png",
          "Logo_UNIFG.png",
          "potiguar.png",
          "CEE.png",
          "UNIFACS.png",
          "uniritter.png"
        ]
      },
      {
        key: "clientes",
        group: "Clientes",
        path: "/static/parceiros/clientes/",
        object: false,
        logos: [
          "neoenergia.png",
          "seguros_unimed.png",
          "bradesco.png",
          "hosp_sirio_libanes.png",
          "magalu.png",
          "hosp_albert_einstein.png",
          "alvarez_marchal.png",
          "accenture.png",
          "adecco.png",
          "novo_nordisk.png",
          "brq.png",
          "licks.png"
        ]
      }
    ]
  }

}
