
































import { Action, Getter, State } from 'vuex-class'; 
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormPlanoCustomizado from '@/views/empresa/cadastro/FormPlanoCustomizado.vue';

@Component({
  components: {
    FormPlanoCustomizado
  }
})
export default class DuvidaProsseguirModal extends Vue {
  public diff:boolean = false;

  public mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId == 'modal-duvida-prosseguir') {
        this.diff = false;
      }
    })
  }
}
