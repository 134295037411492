





































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import TextInput from '@/components/inputs/TextInput.vue';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    PlanCard,
    TextInput,
  },
})
export default class CadastroPlanoGratuito extends Vue {
  private planoCreditosService = new PlanoCredito();
  private planoEscolhido: PlanoCreditoEntity|null = null;

  private get periodoJobcoins() {
    return this.planoEscolhido && this.planoEscolhido.period === EPeriodoPlano.Mensal ? 'Mensais' : 'Anuais';
  }

   public whereToSendCompany() {
    let frontBase = siteCompanyEndPoint;
    let redirectTo =
      sessionStorage.getItem("redirectTo") ||
      this.$route.query.redirectUrl ||
      "/";

    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
        `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
          "access_token"
        )}&redirectTo=${redirectTo}`
      );
    }
  }

  public created() {
    const planoId = sessionStorage.getItem('planoId');

    if (!planoId) {
      // Ainda não preencheu o primeiro form, redirecionar para a home onde o form está
      this.$router.push({ name: 'CadastroDadosDeAcesso', query: {lang: this.$i18n.locale} });
      return;
    }

    this.planoCreditosService.fetch(+planoId)
      .then((data: PlanoCreditoEntity) => {
        // console.log('planoCreditosService.fetch');
        // console.log(data);
        this.planoEscolhido = data;
      })
      .catch((err: any) => {
      });
  }
}
