
























import { Component, Prop, Vue } from "vue-property-decorator";
import Section from '../Section.vue';
import SectionASelecao from './partial/SectionASelecao.vue';
import SectionReduzVies from './partial/SectionReduzVies.vue';
const VueScrollTo = require('vue-scrollto');

@Component({
  components: {
    'app-section': Section,
    SectionASelecao,
    SectionReduzVies
  },
})
export default class SectionSelecaoCegas extends Vue {
  public options!: any;
  public active: number = 1;

  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public goCadastro() {
    this.$bvModal.show('modal-video-tour')
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }
}
