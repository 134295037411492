// tslint:disable:no-string-literal
import ApiRouter from "../../apirouting/EndpointRouterInterface";
import ClientAuthentication from "../ClientAuthentication";
import Repository from "../Repository";
import { CB } from '@/core/util';

export default class TentativaRegistro extends Repository {

  constructor() {
    super(new ApiRouter({create: { url: "/v1/tentativa-registro", method: "POST", headers: {} }}));
  }

  public create(data: any, ctype?: string): Promise<any> {
    const key: string = "create";
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
            rtoken,
            ...data
        };
        const cdta = CB.genC(dta);
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
          reject(resp);
      });
    });
  }

}
