






import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import Header from '@/components/layouts/Header.vue';

@Component({
  components: {
    'app-section': Section,
    'app-header': Header,
  }, 
})
export default class SectionHeader extends Vue {
  
}
