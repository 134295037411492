import { render, staticRenderFns } from "./SectionSelecaoCegas.vue?vue&type=template&id=c06d8532&scoped=true&"
import script from "./SectionSelecaoCegas.vue?vue&type=script&lang=ts&"
export * from "./SectionSelecaoCegas.vue?vue&type=script&lang=ts&"
import style0 from "./SectionSelecaoCegas.vue?vue&type=style&index=0&id=c06d8532&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c06d8532",
  null
  
)

export default component.exports