























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class CheckboxInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: false}) public value!: any;
  @Prop({default: true}) public checkedValue!: any;
  @Prop({default: false}) public uncheckedValue!: any;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
