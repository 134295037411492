











































































import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import PlanCard from '@/components/PlanCard.vue';
import FormCadastro from './FormCadastro.vue';
import FormConfirmation from '@/views/empresa/cadastro/FormConfirmation.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import { EmpresaEntity, EmpresaTrialAccount, PlanoCreditoEntity } from '@/core/models/Entities';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import Alert from '@/components/alerts/Alert.vue';

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    TextInput,
    CheckboxInput,
    TelInput,
    PlanCard,
    Alert,
    FormCadastro,
    FormConfirmation
  },
})
export default class CadastroDadosDeAcesso extends Vue {
  public promise: Promise<any> |null = null;
  private planoCreditosService = new PlanoCredito();

  private trialAccount: EmpresaTrialAccount|null = null;
  private planoEscolhido: PlanoCreditoEntity|null = null;

  private alert: Alert = new Alert();

  private showEmailField = false;
  public  planoId: number|null = null;

  public step: number = 0;

  public empresa: EmpresaEntity|null = null;

  public async created() {
    
    if(!sessionStorage.getItem('access_token')) {
      sessionStorage.removeItem('registroEmpresa')
      sessionStorage.removeItem('dadosEmpresa')
    }

    const dadosEmpresa = sessionStorage.getItem('dadosEmpresa');

    if(dadosEmpresa){
      if(this.$i18n.locale == 'pt-BR'){
        this.$router.push({ name: 'CadastroPagamento', query: {lang: this.$i18n.locale} });
      }else{
        this.$router.push({ name: 'CadastroDadosInvoice', query: {lang: this.$i18n.locale} });
      }
    }
    
    const planoIdentifier: any = this.$route.query.pid;
    if(!planoIdentifier){
      this.planoId = parseInt(sessionStorage.getItem('planoId')+"");
      if( this.planoId &&  this.planoId > 0) {
        await this.planoCreditosService.fetch(+ this.planoId)
            .then((data: PlanoCreditoEntity) => {
                // console.log(data);
                this.planoEscolhido = data;
            })
            .catch((err: any) => {
                // console.log(err);
            });
      }
    } else {
        await this.planoCreditosService.fetchWithData({id:0, planid: planoIdentifier})
          .then((data: PlanoCreditoEntity) => {
              // console.log(data);
              this.planoEscolhido = data;
          })
          .catch((err: any) => {
              // console.log(err);
          });
    }
       
    const registroEmpresa = sessionStorage.getItem('registroEmpresa');
    if (registroEmpresa){
      this.trialAccount = JSON.parse(registroEmpresa)
    }
  }

  get getPlanId() {
    if(this.planoEscolhido?.plan_identifier ==  "free" || this.planoEscolhido?.plan_identifier == "plural")
      return this.planoEscolhido?.plan_identifier;

    return null;
  }

  get periodoJobcoins() {
      return this.planoEscolhido && this.planoEscolhido.period === EPeriodoPlano.Mensal ? '' : 'Mensais';
  }


  public success(data: EmpresaEntity) {
    this.empresa = data;
    this.step++;
    // this.$emit('success',data)
  }

  public successConfirmation(data: any) {
    let frontBase = siteCompanyEndPoint;
    const planoId = sessionStorage.getItem('planoId');
    
    
    if(window.gtag) {
        window.gtag('event','lead-use-grátis-confirmado', { method: 'Empresa', event_category: 'use-grátis', send_to: 'G-V2D4Z1PD6G' });
        window.gtag('event','lead-use-grátis-confirmado', { method: 'Empresa', event_category: 'use-grátis', send_to: 'UA-93012005-1' });
        window.gtag('event','lead-use-grátis-confirmado', { method: 'Empresa', event_category: 'use-grátis', send_to: 'AW-873758741' });
    }

    if(planoId){
      if(this.planoEscolhido!.free == true){

        if(window.fbq)
          window.fbq('track', 'AddToCart');

        if (sessionStorage.getItem("access_token")) {
          window.location.replace(
            `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
              "access_token"
            )}&redirectTo=/`
          );
        }
      } else {
        if(this.$i18n.locale == 'pt-BR'){
          this.$router.push({ name: 'CadastroPagamento', query: {lang: this.$i18n.locale} });
        }else{
          this.$router.push({ name: 'CadastroDadosInvoice', query: {lang: this.$i18n.locale} });
        }
      }
    }else{
      this.$router.push({ name: 'CadastroEscolherPlano', query: {lang: this.$i18n.locale} });
    }
  }

  public error() {
    console.log('err')
  }

}
