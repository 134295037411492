








import { Component, Vue } from "vue-property-decorator";
import Footer from "./components/layouts/Footer.vue";

@Component({
  components: {
    "app-footer": Footer
  }
})
export default class App extends Vue {
  public mounted() {
      setTimeout( () => {
        try {
           /* PROGRAMMATICALLY INJECT JS HERE */
          var recaptchaJs = document.createElement('script');
          recaptchaJs.setAttribute('src','https://www.google.com/recaptcha/api.js?render='+process.env.VUE_APP_RECAPTCHA_SITEKEY);
          document.head.appendChild(recaptchaJs);
        } catch(e) {
          console.log(e)
        }
      },5000)
  }
}
