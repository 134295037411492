


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MobileFeatureCard extends Vue {
}
