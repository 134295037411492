// tslint:disable
import Vue from "vue";
import VueI18n from "vue-i18n";

const localesptBR = require("@/assets/locales/common/pt_br.json");
const localesEN = require("@/assets/locales/common/en.json");
const localesES = require("@/assets/locales/common/es.json");
const typesEN = require("@/assets/locales/types/en.json");
const typesES = require("@/assets/locales/types/es.json");
const typesptBR = require("@/assets/locales/types/pt_br.json");
//ElementLocale.use(enLocale.el)

const messages = {
  "pt-BR": {
    ...localesptBR.pt_br,
    ...typesptBR.pt_br
  },
  pt_br: {
    ...localesptBR.pt_br,
    ...typesptBR.pt_br
  },
  en: {
    ...localesEN.en,
    ...typesEN.en
  },
  "en-US": {
    ...localesEN.en,
    ...typesEN.en
  },
  es: {
    ...localesES.es,
    ...typesES.es
  }
};

// Resource logic
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'pt-BR', // set locale
    fallbackLocale: 'en-US',
    messages,
})
