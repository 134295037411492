
















import { Component, Prop, Vue } from "vue-property-decorator";
import RadialProgressBar from 'vue-radial-progress'

@Component<ProgressProfile>({
  components: {
    RadialProgressBar,
  },
})
export default class ProgressProfile extends Vue {

  @Prop({ default: "" }) 
  public percentage!: string;
  
  @Prop({ default: 150 }) 
  public diameter!: number;

  @Prop({ default: 100 }) 
  public totalSteps!: number;
  
  @Prop({ default: 10 }) 
  public strokeWidth!: number;
  
  @Prop({ default: "" }) 
  public message!: string;
  
  @Prop({ default: "#aaaaaa" }) 
  public startColor!: string;
  
  @Prop({ default: "#cccccc" }) 
  public stopColor!: string;


  get intPercentage() :number {
    return parseInt(this.percentage)
  }

  public progress(event,progress,stepValue){
    /* console.log(stepValue) */
  }
  public progress_end(event){
    /* console.log("Circle progress end") */
  }
}
