








































import { Component, Vue, Prop } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import DuvidasCollapse from '@/components/duvidas/DuvidasCollapse.vue';

@Component({
  components: {
    Section,
    DuvidasCollapse,
  },
})
export default class Duvidas extends Vue {
  public duvidasList!: any[];

  @Prop({ default: 'company' })
  public type!: string;

  get helpcenterURL() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    return this.type=="company" ? `https://ajuda.jobecam.com/${locale}/category/2` : `https://ajuda.jobecam.com/${locale}/category/1`;
  }

  public created() {
    this.duvidasList = [
      {
        question: 'Eu pago para participar?',
        answer: 'Não! A plataforma de empregos da Jobecam é totalmente gratuita para candidatos!',
        type: 'candidate'
      },
      {
        question: 'Quais são as vagas disponíveis?',
        answer: 'Temos diversas vagas disponíveis no momento. Para você ter acesso a todas elas é só se cadastrar <a href="https://candidato.jobecam.com/register?lang=pt-BR">clicando aqui</a>.',
        type: 'candidate'
      },
      {
        question: 'O que preciso para fazer o cadastro?',
        answer: 'Você só precisa de um e-mail para criar um login e senha à partir dele <a href="https://candidato.jobecam.com/register?lang=pt-BR">por aqui</a>. Você pode também se cadastrar usando o seu perfil do LinkedIn e do Facebook.',
        type: 'candidate'
      },
      {
        question: 'Como gravo meu vídeo currículo?',
        answer: 'Crie seu perfil Jobecam e grave a qualquer momento e em qualquer lugar o seu vídeo currículo. Você tem 30 segundos para mostrar todo seu potencial para o recrutador!',
        type: 'candidate'
      },
      {
        question: 'Como acesso a Jobecam?',
        answer: 'Basta clicar em “Quero me candidatar” na página inicial da plataforma ou acessar através do seu login e senha no botão “Entrar”.',
        type: 'candidate'
      },
      {
        question: 'Quem tem acesso ao meu perfil Jobecam?',
        answer: 'Apenas os recrutadores e empresas terão acesso ao seu perfil. Você também pode tornar o seu perfil público e divulgar nas suas redes sociais e LinkedIn.',
        type: 'candidate'
      },
      {
        question: 'Como começar a usar a Jobecam?',
        // answer: 'Cadastre a sua empresa agora mesmo, contrate um dos nossos planos com apenas 1 clique e já comece a recrutar os melhores talentos utilizando a Jobecam.',
        answer: 'Encontre o <a href="https://jobecam.com/empresa/cadastro/planos?lang=pt-BR">plano que mais se adequa a sua necessidade</a>, compre e já saia usando. Se preferir, você também pode falar com um de nossos especialistas ou iniciar sua experiência com a nossa seleção às cegas ao vivo.',
        type: 'company'
      },
      // {
      //   question: 'Posso fazer um teste?',
      //   answer: 'Comece sua experiência Jobecam com o nosso pacote degustação com 2 jobcoins. Ou fale com um consultor, enviando um e-mail para contato@jobecam.com',
      //   type: 'company'
      // },
      // {
      //   question: 'O que são Jobcoins?',
      //   answer: 'Os Jobcoins são as “moedas” da Jobecam. Cada Jobcoin equivale a 1 entrevista gravada (com até 4 perguntas de até 2 minutos cada, delimitadas por você).',
      //   type: 'company'
      // },
      {
        question: 'Quero recrutar às cegas, como faço?',
        // answer: 'Participe do nosso #DesafioAsCegas e experimente recrutar talentos de uma forma disruptiva e levando mais diversidade para a sua empresa. Converse com um dos nossos consultores e saiba mais.',
        answer: 'Crie agora mesmo a sua primeira sala de Entrevista Às Cegas Ao Vivo. Convide gestores para participar dessa experiência e leve um processo muito mais inovador e inclusivo para a sua empresa.',
        type: 'company'
      },
      {
        question: 'Como é a Jobecam para o meu candidato?',
        answer: 'A Jobecam é totalmente gratuita para candidatos, oferecemos um suporte premium com um time de Candidate Experience focado em fazer com que ele se sinta valorizado do começo ao fim da seleção. <a href="https://www.jobecam.com?lang=pt-BR">Saiba mais sobre a Jobecam para candidatos aqui.</a>',
        type: 'company'
      },
      {
        question: 'A Jobecam é um ATS?',
        // answer: 'Não, mas nossa API é integrável com qualquer sistema. Saiba como integrar a Jobecam ao seu sistema, envie um e-mail para contato@jobecam.com',
        answer: 'Não somos um ATS, mas é possível realizar integrações com os principais sistemas do mercado. Fale com o seu gerente de contas ou envie um e-mail para contato@jobecam.com e saiba mais.',
        type: 'company'
      }

    ]
  }
}
