



















































































// https://storage.googleapis.com/jobecam-assets/img-01.png
import { Component, Vue } from 'vue-property-decorator';
import SectionHeader from './sections/SectionHeader.vue';
import Section from './sections/Section.vue';
import SectionPorqueJobecam from './sections/SectionPorqueJobecam.vue';
import SectionNossaTecnologia from './sections/SectionNossaTecnologia.vue';
import SectionMude from './sections/SectionMude.vue';
import SectionDigaSim from './sections/SectionDigaSim.vue';
import SectionFuncionalidadesJobecam from './sections/section-funcionalidades-jobecam/SectionFuncionalidadesJobecam.vue';
import SectionComoAjudar from './sections/section-como-ajudar/SectionComoAjudar.vue';
import SectionTwoColumns from './sections/section-cols/SectionTwoColumns.vue';
import SectionSelecaoCegas from './sections/section-selecao-cegas/SectionSelecaoCegas.vue';
import SectionFuncionaModalidade from './sections/section-selecao-cegas/partial/SectionFuncionaModalidade.vue';
import SectionNaMidia from './sections/section-selecao-cegas/partial/SectionNaMidia.vue';
// import SectionThreeColumns from './sections/section-cols/SectionThreeColumns.vue';
import SectionElesNosEscolheramParceiros from './sections/SectionElesNosEscolheramParceiros.vue';
import SectionElesNosEscolheramClientes from './sections/SectionElesNosEscolheramClientes.vue';
// import SectionCases from './sections/SectionCases.vue';
import SectionDepoimentos from './sections/SectionDepoimentos.vue';
import SectionRecrutamento from './sections/SectionRecrutamento.vue';
import SectionPlanos from './sections/SectionPlanos.vue';
import SectionMonteSeuPlano from './sections/SectionMonteSeuPlano.vue';
import Duvidas from '@/components/duvidas/Duvidas.vue';
import Contato from '@/components/contato/Contato.vue';
import LoginModal from '@/components/login/LoginModal.vue';
import SolicitarDiagnosticoModal from '@/components/modal/SolicitarDiagnosticoModal.vue';
import ImageModal from '@/components/modal/ImageModal.vue';
import CardHighlight from '@/components/cards/CardHighlight.vue';

@Component({
  components: {
    SectionHeader,
    SectionPorqueJobecam,
    SectionNossaTecnologia,
    SectionMude,
    SectionDigaSim,
    SectionFuncionalidadesJobecam,
    SectionNaMidia,
    SectionComoAjudar,
    SectionTwoColumns,
    'app-section': Section,
    SectionElesNosEscolheramParceiros,
    SectionElesNosEscolheramClientes,
    SectionSelecaoCegas,
    // SectionCases,
    SectionPlanos,
    SectionMonteSeuPlano,
    SectionDepoimentos,
    Duvidas,
    Contato,
    LoginModal,
    SectionRecrutamento,
    SolicitarDiagnosticoModal,
    SectionFuncionaModalidade,
    ImageModal,
    CardHighlight
  },
})
export default class Home extends Vue {
  public pidTimeout: number|null = null;
    
  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public created() {
    this.cleanSession()
  }

  get locale() {
    return this.$i18n.locale.toLocaleLowerCase().replace('_','-');
  }

  public showModalDiagnostico() {
    this.$bvModal.hide('call-diag')
    this.$bvModal.show('solicitar-diagnostico-modal')
    clearTimeout(this.pidTimeout||0);
  }

  public clearCurrentTimeOut() {
    clearTimeout(this.pidTimeout||0);
    this.$bvModal.hide('call-diag')
    this.$bvModal.hide('solicitar-diagnostico-modal')
  }

  public openReg() {
    this.$bvModal.show('cadastro-empresa-modal')
    this.$bvModal.hide('call-diag')
    this.$bvModal.hide('call-plan')
    this.$bvModal.hide('call-mgm')
    this.$bvModal.hide('solicitar-diagnostico-modal')
  }

  public mounted() {
    // this.pidTimeout = setTimeout(() => {
    //   this.showModalDiagnostico()
    // },120000)
    
    setTimeout(() => {
       if(this.$route.name == "HomeEmpresa" && !this.$route.query.lang) {
          let lang: string = !this.$route.query.lang ? navigator.language : (this.$route.query.lang as string);
          if (!lang) {
            lang = navigator.language;
          }
          // console.log(lang);

          if (['en', 'es', 'pt'].indexOf(lang.substring(0, 2)) >= 0) {
            if (lang.substring(0, 2) == 'pt') {
              this.$i18n.locale = 'pt-BR';
            } else {
              this.$i18n.locale = lang.substring(0, 2);
            }
          } else {
            this.$i18n.locale = 'en';
          }
       }
    },5000)
    

  }

  public gottoPlan() {
    this.$bvModal.hide('call-diag')
    this.$bvModal.hide('solicitar-diagnostico-modal')
    clearTimeout(this.pidTimeout||0);
    this.$router.push({path: '/empresa/cadastro/planos', query: {lang: this.$i18n.locale }})
    return;
  }
}
