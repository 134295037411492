import VueI18n from "vue-i18n";

// tslint:disable
export class ScriptAsyncLoad {
    [index: string]: any|undefined;
    private address: string;
    private dependencies?: ScriptAsyncLoad[]|null;
    private maxCounter = 10000;

    constructor(add: string, dp: ScriptAsyncLoad[]|null = null) {
        this.address = add;
        this.dependencies = dp;
    }

    public load(): any {
        if (this.address && document && document.head) {
            // var hash = (new Date()).getTime();
            const sc: HTMLScriptElement = (<HTMLScriptElement>document.createElement("script")); // casting with as
            sc.setAttribute("type", "text/javascript");
            sc.setAttribute("async", "true");
            sc.setAttribute("src", this.address);
            // sc.setAttribute("src", this.address+"?v="+hash);
            document.head!.appendChild(sc) ;

            if (this.dependencies) {
                const me: any = this;
                for (const dependency of me.dependencies) {
                    dependency.load();
                }
            }
            return true;
        }
        console.error("Could Not load Scripts the chain must be complete");
        return false;
    }
}

export class FilterElement {
    [index: string]: any|undefined;
    public name: string;
    public isSelectable: boolean = false;
    public elements? : Array<any>;
    public model:any;
    public callback: any

    constructor(name: string, selectable?: boolean, multiple?: boolean, callback?: any) {
        this.name = name;
        this.isSelectable = (!!selectable);
        this.model = "";

        if (this.isSelectable) {
            this.elements = new Array();
            this.elements.push({ label: "Selecione", value: "Selecione" });
        }
        if (multiple) {
            this.model = [];
        }
        if (callback) {
            this.callback = callback
        } else {
            this.callback = () => { console.log(this.model) }
        }
    }

    loadElements (promisse: Promise<any>, val: string, label: string, i18n: VueI18n, useI18n: boolean, identifier?: string): any {
        promisse.then((data:any) => {
            this.elements = new Array();
            this.elements.push({ label: i18n.t("Selecione"), value: i18n.t("Selecione") });
            let arr:any = (identifier) ? data[identifier] : data;
            for (let index:number = 0; index < arr.length; index++) {
                const v:any = arr[index];
                if(useI18n){
                    this.elements!.push({ label: i18n.t(""+identifier+"."+v[val]), value: v[val] });
                }else{
                    this.elements!.push({ label: v[label], value: v[val] });
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    }

}

export class JobsInvites {

    public addToList(vagaUrl?: string, id?: number){
        let invites: string|null = sessionStorage.getItem('invites')
        // console.log(invites)
        let inv: any = { vagaUrl, id }
        try {
            if (invites) {
                inv = JSON.parse(invites);
            }
        } catch(e) {
            inv = { vagaUrl, id }
        } finally {
            if(vagaUrl)
                inv[vagaUrl] = this;
            sessionStorage.setItem('invites', JSON.stringify(inv));
        }
    }

    public isOnList(vagaUrl?: string): boolean
    {
        if(!vagaUrl)
            return false;

        let inv: any = {}
        let invites: string|null = sessionStorage.getItem('invites')
        // console.log(invites)
        try {
            if(invites){
                inv = JSON.parse(invites);
            }
        } catch(e) {
            console.log('erro no parse do json de invites')
        } finally {
            let keys: string[] = Object.keys(inv);
            if(keys.indexOf(vagaUrl) >=0 ){
                console.log('esta convidado')
                return true;
            }
            return false;
        }
    }

}

export class CB {
        
    public static getRandom() {
        const min: number = Math.ceil(Math.random() * 10);
        const max: number = Math.floor(Math.random() * 100);
        const x1 = '' + Math.floor(Math.random() * (max - min)) + min;
        const x2 = '' + Math.floor(Math.random() * (max - min)) + min;
        return btoa(x1 + x2).replace(/=/g, '');
    }

    public static genC(data: any) {
        const scs: string = CB.getRandom();
        const stringData = scs + '==' + btoa(JSON.stringify(data));
        return { _: btoa(stringData) };
    }
}


export class PasswordStrengh {
    /* public strongRegex: RegExp|null = null;
    public mediumRegex: RegExp|null = null;
    public atLeastOneLower: RegExp|null = null;
    public atLeastOneUpper: RegExp|null = null;
    public atLeastOneNumber: RegExp|null = null;
    public atLeastSixChars: RegExp|null = null;
    public atLeastEightChars: RegExp|null = null;
    public atLeastOneSpecialChar: RegExp|null = null;
    public password: string = '';

    constructor(){
        this.strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z1-9])(?=.{8,})');
        this.mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
        this.atLeastOneLower = new RegExp('^(?=.*[a-z])');
        this.atLeastOneUpper = new RegExp('^(?=.*[A-Z])');
        this.atLeastOneNumber = new RegExp('^(?=.*[0-9])');
        this.atLeastSixChars = new RegExp('^(?=.{6,})');
        this.atLeastEightChars = new RegExp('^(?=.{8,})');
        this.atLeastOneSpecialChar = new RegExp('^(?=.*[^a-zA-Z1-9])');
    } */

    public static isValidPassword(password: string): boolean
    {
        return PasswordStrengh.isStrong(password);
    }

    public static isStrong(password: string): boolean
    {
        return (new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z1-9])(?=.{8,})')).test(password);
    }

    public static isMedium(password: string): boolean
    {
        return (new RegExp('^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')).test(password);
    }

    public static hasOneLower(password: string): boolean
    {
        return (new RegExp('^(?=.*[a-z])')).test(password);
    }

    public static hasOneUpper(password: string): boolean
    {
        return (new RegExp('^(?=.*[A-Z])')).test(password);
    }


    public static hasSixChars(password: string): boolean
    {
        return (new RegExp('^(?=.{6,})')).test(password);
    }

    public static hasEightChars(password: string): boolean
    {
        return (new RegExp('^(?=.{8,})')).test(password);
    }

    public static hasOneSpecialChar(password: string): boolean
    {
        return (new RegExp('^(?=.*[^a-zA-Z1-9])')).test(password);
    }



    public static hasOneNumber(password: string): boolean
    {
        return (new RegExp('^(?=.*[0-9])')).test(password);
    }

    public static checkSequence(password: string): boolean 
    {
        const s: string  = password;
    
        if(s) {
           var test = (x) => !isNaN(parseInt(x));
           var check = (x, y, i) => x + i === y;
        
           for(var i = 0; i < s.length - 2; i++) {
             if(test(s[i])) {
                if(test(s[i + 1]) && test(s[i + 2])) {
                  if(check(parseInt(s[i]), parseInt(s[i + 1]), 1) &&
                    check(parseInt(s[i]), parseInt(s[i + 2]), 2)) {
                    return false;
                  }
                }
             } else if(!test(s[i + 1]) && !test(s[i + 2])) {
                if(check(s.charCodeAt(i), s.charCodeAt(i + 1), 1) &&
                    check(s.charCodeAt(i), s.charCodeAt(i + 2), 2)) {
                    return false;
                  }
             }
           }
          
        }
        
        return true;
    }

}
