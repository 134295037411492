// tslint:disable:no-string-literal
import ApiRouter from "../../apirouting/EndpointRouterInterface";
import Repository from "../Repository";
export default class PlanoCredito extends Repository {

  public static PARTIAL: string = "part";
  public static FULL: string = "full";

  constructor() {
    super(new ApiRouter({
        fetch: { url: "/v1/plano-credito/{id}", method: "GET", headers: {} },
        fetchAll: { url: "/v1/plano-credito", method: "GET", headers: {} }}));
  }


}
