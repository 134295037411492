
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';
import { CB } from '@/core/util';


export default class LinkedIn extends Repository {
    constructor() {
        super(new ApiRouter({
            // fetch: { url: '/v1/linked-in/0', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/social-login', method: 'GET', headers: {} }
        }));
    }

    public call(key: string, data: any, ctype: string): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call(key, data, ctype).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call(key, cdta, ctype).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            }
        });
    }
}


