




























import { Component, Vue, Prop } from 'vue-property-decorator';
import AccountCofirmation from '@/core/Services/Empresa/AccountCofirmation';
import Alert from '@/components/alerts/Alert.vue';

@Component
export default class FormConfirmation extends Vue {
  @Prop({default: null})
  public email!: string|null;

  @Prop({default: null})
  public buttonText!: string|null;

  public promise: Promise<any> |null = null;
  private service = new AccountCofirmation();
  private alert: Alert = new Alert();

  public code: string|null = null;

  public submitForm() {
    if(this.code && this.email) {
      this.promise = this.service.create({
        email: this.email,
        code: this.code
      }).then((data: any) => {
        this.$emit('success',data)
      }).catch((ee) => {
        this.alert.addMsg(this.$t('Código informado é inválido').toString())
        this.alert.callBoxWarn()
        this.$emit('error',ee)
      })
    } 
  }

  get state() {
    return this.code && this.code.length == 6
  }

  get invalidFeedback() {
      if (this.code && this.code.length != 6) {
        return this.$t('Código terá 6 caracteres')
      }
      return ''
    }

}
