















import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';

@Component({
  components: {
    'app-section': Section,
  },
})
export default class SectionHeader extends Vue {}
