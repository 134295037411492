














import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ScriptAsyncLoad } from "@/core/util";
import SectionHeader from "../sections/SectionHeader.vue";
import PortugueseTerms from '@/views/terms/privacy/PortugueseTerms.vue';
import SpanishTerms from '@/views/terms/privacy/SpanishTerms.vue';
import EnglishTerms from '@/views/terms/privacy/EnglishTerms.vue';
import LoginModal from "@/components/login/LoginModal.vue";
const namespace: string = "profile";

@Component<TermsPrivacy>({
	components: {
    SectionHeader,
    LoginModal,
    'portuguese': PortugueseTerms,
    'spanish': SpanishTerms,
    'english': EnglishTerms
	}
})
export default class TermsPrivacy extends Vue {

}
