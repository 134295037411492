

























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  @Prop({default: ''}) public label!: string;
  @Prop({default: ''}) public name!: string;
  @Prop({default: 'text'}) public type!: string;
  @Prop({default: ''}) public value!: string;
  @Prop({default: false}) public required!: boolean;
  @Prop({default: ''}) public placeholder!: string;
  @Prop({default: ''}) public variant!: string;
  @Prop({default: null}) public state!: null|boolean|string;
  @Prop({default: ''}) public invalidFeedback!: string;


  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
