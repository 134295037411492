// tslint:disable
import ApiRouter from "@/core/apirouting/EndpointRouterInterface";
import { clientId } from "@/core/endpoint";
import { EmpresaEntity } from "@/core/models/Entities";
import ClientAuthentication from "../ClientAuthentication";
import Repository from "../Repository";
import { CB } from '@/core/util';


export default class Empresa extends Repository {

  public entidade: EmpresaEntity;

  constructor(i18n?: any) {
    super(new ApiRouter({
        auth: { url: "/v1/company-auth", method: "POST", headers: {} },
        create: { url: "/v1/empresa", method: "POST", headers: {} },
        fetch: { url: "/v1/empresa/{id}", method: "GET", headers: {} },
        fetchAll: { url: "/v1/empresa", method: "GET", headers: {} }, // tem parametro de Busca "s"
        info: { url: "/v1/my-info", method: "GET", headers: {} } ,
        update: { url: "/v1/empresa/{id}", method: "PUT", headers: {} }}));
    if (i18n) {
      this.$i18n = i18n;
    }
    this.entidade = new EmpresaEntity();
  }

  public create(data: any, ctype?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        if(rtoken == null) {
          reject(new Error('RECAPTCHA_NOT_LOADED'));
          return;
        }
         
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
        reject(resp);
      });
    });
  }

  public loadEntidade(promisse: Promise<Empresa>, callback?: () => void) {
    promisse.then((data: any) => {
      this.entidade = data;
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line:no-console
      console.log("Couldn't load my info.", err);
    });
  }

  public loadFromNomeUrl(url: string, callback?: () => void): void {
    if (sessionStorage.getItem("access_token") !== null) {
        this.loadEntidade(super.call("fetch", { id: 0, urlCode: url }, "application/json"), callback);
    } else {
      this.publicLoadFromNomeUrl(url, callback);
    }
  }

  public publicLoadFromNomeUrl(url: string, callback?: () => void): void {
    (new ClientAuthentication()).auth().then((identity: any) => {

      if (identity["access_token"] == null || identity["access_token"] === undefined) {
        throw new Error("Token de acesso inválido");
      }

      const token = identity["token_type"] + " " + identity["access_token"];
      this.endPointAPI.fetch.headers["Authorization"] = token;
      this.loadEntidade(super.call("fetch", { id: 0, urlCode: url }, "application/json"), callback);
    }).catch((resp) => {
      // tslint:disable-next-line:no-console
      console.log(resp);
    });
  }

  public whatIsMissing(perfil: any): string {
    let msg: string = "";
    if(perfil){
      msg += !(perfil.nomeEmpresa) ? this.$i18n.t("Nome Empresa") + "<br />" : "";
      msg += !(perfil.cep) ? this.$i18n.t("CEP") + "<br />" : "";
      msg += !(perfil.endereco) ? this.$i18n.t("Endereço") + "<br />" : "";
      msg += !(perfil.bairro) ? this.$i18n.t("Bairro") + "<br />" : "";
    }
    return msg;
  }

  public checkCompanyStatus(){

    const currPath =  this.getRouter().currentRoute.fullPath;
    let notOnSettings: boolean = currPath.indexOf('/empresa/settings')==-1;
    let notOnPlanos: boolean = (
        currPath.indexOf('planos') == -1 &&
        currPath.indexOf('comprar-plano') == -1 &&
        currPath.indexOf('pagamento-plano') == -1 &&
        currPath.indexOf('fatura-plano') == -1 &&
        currPath.indexOf('atualizar-cartao') == -1
      );

    if(!notOnPlanos)
      return

    if(this.entidade.statusPagamento == '5' && !notOnSettings){ // CONCLUIDO && PENDENTE
      this.getRouter().push({path: "/empresa/comprar-plano", query: { lang: this.getI18n().locale }});
      return false;
    } else if(this.entidade.statusCadastro == '5' && notOnSettings){ // PENDENTE
      const msg: string = this.whatIsMissing(this.entidade.perfil)

      /* $.alert({
        animation: "scale",
        closeIcon: true,
        columnClass: "col-md-6",
        content: "" + this.$i18n.t("Favor informar") + ":<br />" + msg,
        icon: "fa fa-lock",
        theme: "material",
        title: "" + this.$i18n.t("Falta pouco! Termine seu cadastro para continuar") ,
        type: "red",
      }); */
      this.getRouter().push({path: "/empresa/settings", query: { lang: this.getI18n().locale }});
      return false;
    }
    return true;
  }

  public loadMyInfo(callback?: () => void): void {
    super.myInfo().then((data: any) => {
      if(data["type"] == "company"){
        this.entidade = data["entity"];
      }else if(data["type"] == "recruiter"){
        this.entidade = data["entity_company"];
      }

      this.checkCompanyStatus()

      if (callback) {
        callback()
      }
    }).catch((err: any)=> {
      console.log("Couldn't load my info.", err);
    });
  }

  public auth(data: any): Promise<any> {
    data["client_id"] = atob(clientId);
    data["grant_type"] = "company";

    return super.auth(data);
  }

  public async authRecruiter(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = 'recruiter';
    

    return super.auth(data);
  }
}
