








import { Action, Getter, State } from 'vuex-class'; 
import { Component, Vue, Prop } from 'vue-property-decorator';
import FormDiagnostico from '@/views/empresa/cadastro/FormDiagnostico.vue';

@Component({
  components: {
    FormDiagnostico
  }
})
export default class ImageModal extends Vue {
  @Prop({default: ''})
  public imgSrc!:string;

  @Prop({default: 'image-modal'})
  public mid!:string;
}
