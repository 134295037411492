
























// tslint:disable
// require("./static/css/layouts/page-center.css")
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import Candidato from "@/core/Services/Candidato/Candidato";
import { baseEndPoint, clientId } from "@/core/endpoint";
import ShareSession from '../../plugins/ShareSession';
const namespace: string = "profile";


@Component
export default class ThirdPartyLoginSuccess extends Vue {

  @Action("userRequest", { namespace }) public  userRequest: any;

  public contentText: string = "";
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
  public candidato!: Candidato;

  public closeWindow() {
    window.close()
  }


  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }

  public created() {
    if(this.$route.query.accessToken) {
      // this.candidato =  new Candidato();
      sessionStorage.setItem("access_token", this.$route.query.accessToken+'');
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", "candidate");
      this.userRequest().then(() => {
        console.log("ok")
        this.contentText =  ""+this.$t("Acesso liberado – Seja bem vindo à Jobecam");
      }).catch(() => {
        this.removeItensFromStorage();
        this.contentText = ""+this.$t("Ocorreu um problema");
      })
    }else {
      this.removeItensFromStorage();
      this.contentText = ""+this.$t("Ocorreu um problema");
    }
  }

}
