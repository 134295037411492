


































import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from '@/components/layouts/Header.vue';
import Section from '@/components/layouts/Section.vue';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";
import LoginModal from '@/components/login/LoginModal.vue';
import { EmpresaTrialAccount, PlanoCreditoEntity } from '@/core/models/Entities';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';

@Component({
  components: {
    Header,
    Section,
    LoginModal,
  }
})
export default class CadastroPagamentoSucesso extends Vue {
  private trialAccount!: EmpresaTrialAccount;
  private planoEscolhido: PlanoCreditoEntity|null = null;
  private planoCreditosService = new PlanoCredito();

  public created() {
    const planoId = sessionStorage.getItem('planoId');

    if (!planoId) {
      // Ainda não escolheu plano
      this.$router.push({ name: 'CadastroEscolherPlano', query: {lang: this.$i18n.locale} });
      return;
    }

    this.trialAccount = JSON.parse(sessionStorage.getItem('registroEmpresa')!);

    this.planoCreditosService.fetch(+planoId)
      .then((data: PlanoCreditoEntity) => {
        this.planoEscolhido = data;
      })
      .catch((err: any) => {
      });
  }

  public whereToSendCompany() {
    let frontBase = siteCompanyEndPoint;
    let redirectTo =
      sessionStorage.getItem("redirectTo") ||
      this.$route.query.redirectUrl ||
      "/";

    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
        `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
          "access_token"
        )}&redirectTo=${redirectTo}`
      );
    }
  }

}
