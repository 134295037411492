// tslint:disable
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { ProfileState } from "./types";
import { CandidatoEntity } from '@/core/models/Entities';
import router from '@/router'

export const getters: GetterTree<ProfileState, RootState> = {
    authorizationHeader(state: any): string {
        return `${sessionStorage.getItem("token_type")} ${sessionStorage.getItem("access_token")}`;
    },
    getProfile(state: any): any {
        return sessionStorage.getItem("profile");
    },
    getProfileType(state:any):any {
        return sessionStorage.getItem("profile_type");
    },
    isAuthenticated(state:any):boolean {
        const c:boolean = !!(state.token||sessionStorage.getItem("access_token"));
        console.log(c)
        console.log(state.token)
        console.log(sessionStorage.getItem("access_token"))
        return c
    },
    authStatus(state:any):any {
        return state.status;
    },
    candidatoInfo(state: ProfileState): CandidatoEntity|undefined|null {
        return state.candidato_info;
    },
    isEntrevistaPage() {
        return router.currentRoute.name === 'Entrevista';
    }
};
