








import { Component, Vue } from 'vue-property-decorator';
import SectionProgress from './sections/SectionProgress.vue';
import SectionHeader from './sections/SectionHeader.vue';
import SectionSecure from './sections/SectionSecure.vue';

@Component({
  components: {
    SectionHeader,
    SectionProgress,
    SectionSecure,
  }
})
export default class RootCadastro extends Vue {}
