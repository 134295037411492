
















































































































































































import { Action, Getter, State } from 'vuex-class';
import {
  siteCandidateEndPoint,
  baseEndPoint,
  clientId,
  siteCompanyEndPoint,
  siteEndPoint
} from '@/core/endpoint';
import { Component, Vue, Prop } from 'vue-property-decorator';
import FBService from '@/core/Services/ThirdParty/Facebook';
import LNService from '@/core/Services/ThirdParty/LinkedIn';
import TextInput from '@/components/inputs/TextInput.vue';
// import FacebookRegister from '@/components/register/FacebookRegister.vue';
// import LinkedinRegister from '@/components/register/LinkedinRegister.vue';
import { LinkedInUser } from '@/core/models/Entities';
const VueScrollTo = require('vue-scrollto');

const namespace: string = 'profile';
let PIDINTERVAL: any = 0;

@Component({
  components: {
    TextInput,
    // FacebookRegister,
    // "linkedin-button": LinkedinRegister
  }
})
export default class LoginModal extends Vue {
  public currentPromise: any | null = null;
  public usernameCandidato: string = "";
  public uri: string =
    window.document!.location!.protocol +
    "//" +
    window.document!.location!.host;
  public fbService!: FBService;
  public lnService!: LNService;
  public passwordCandidato: string = "";
  public usernameEmpresa: string = "";
  public passwordEmpresa: string = "";
  public myPopUp: Window | null = null;
  public showDismissibleAlertCandidato: boolean = false;
  public showDismissibleAlertEmpresa: boolean = false;
  public alertVariant: string = "danger";
  public serverMessage?: string | null = "";
  private linkedinToken: any = '';
  public options!: any;

  @Action("authRequest", { namespace: "profile" }) public authRequest: any;
  @Action("authRequestCompany", { namespace }) authRequestCompany: any;
  @Action("userRequest", { namespace }) public userRequest: any;

  @Prop({ default: 'candidato' })
  private firstTabToShow!: string;

  private tabActive: number = 0;

  public created() {
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
    if (this.firstTabToShow === 'candidato') {
      this.tabActive = 0;
    }
    else {
      this.tabActive = 1;
    }

    sessionStorage.removeItem("redirectTo");
    this.fbService = new FBService();
    this.lnService = new LNService();
  }

  private getRedirectTo() {
    return sessionStorage.getItem("redirectTo") ||
      this.$route.query.redirectUrl ||
      "/";
  }

  public loggedInFacebookRegistered(accessToken: string) {
    this.registerUser(accessToken);
    this.whereToSend();
  }

  public loggedInFacebookNotRegistered(accessToken: string) {
    const redirectTo = this.getRedirectTo();

    window.location.replace(
      `${siteCandidateEndPoint}/register?authType=facebook&accessToken=${accessToken}&redirectTo=${redirectTo}`
    );
  }

  public async loginCompany() {
    let frontBase = siteCompanyEndPoint;
    window.location.replace(`${frontBase}}`);
  }

  public async login() {
    let frontBase = siteCandidateEndPoint;
    window.location.replace(`${frontBase}}`);

  }

  public whereToSendCompany() {
    let frontBase = siteCompanyEndPoint;
    let redirectTo =
      sessionStorage.getItem("redirectTo") ||
      this.$route.query.redirectUrl ||
      "/";

    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
        `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
          "access_token"
        )}&redirectTo=${redirectTo}&profile_type=${sessionStorage.getItem("profile_type")}`
      );
    }
  }

  public whereToSend() {
    let frontBase = siteCandidateEndPoint;
    let redirectTo =
      sessionStorage.getItem("redirectTo") ||
      this.$route.query.redirectUrl ||
      "/";

    if (sessionStorage.getItem("access_token")) {
      window.location.replace(
        `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
          "access_token"
        )}&redirectTo=${redirectTo}`
      );
    }

    if (sessionStorage.getItem('linkedin_user')) {
      window.location.replace(
        `${frontBase}/redirectLogin?lang=${this.$i18n.locale}&p=${sessionStorage.getItem(
          "linkedin_user"
        )}&redirectTo=${redirectTo}`
      );
      sessionStorage.removeItem('linkedin_user');
    }
  }
  
  
  public sendToCandidateFront() {
    let frontBase = siteCandidateEndPoint;
    window.location.replace(
      `${frontBase}/login?lang=${this.$i18n.locale}`
    );
  }
  
  public sendToCompanyFront() {
    let frontBase = siteCompanyEndPoint;
    window.location.replace(
      `${frontBase}/login?lang=${this.$i18n.locale}`
    );
  }

  public async registerUser(at: string) {
    if (at) {
      // this.candidato =  new Candidato();
      sessionStorage.setItem("access_token", at + "");
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", "candidate");
      await this.userRequest()
        .then(() => {
          console.log("ok");
        })
        .catch(() => {
          this.removeItensFromStorage();
        });
    } else {
      this.removeItensFromStorage();
    }
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }
  public getParameterByName(name: any, url: any) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  public checkClosedWindow(w: Window | null, func: Function) {
    if (w !== null && w.closed) {
      clearInterval(PIDINTERVAL);
      func.call(0);
    }
  }
 public linkedInPopupClosed() {
    this.whereToSend();
  }

  public async loginWithLinkedIn(value: string, type: string = 'access') {
    if (this.linkedinToken) {
      return;
    }
    if (type == 'access') {
      this.linkedinToken = value;
      await this.registerUser(this.linkedinToken);
    }
    else {
      sessionStorage.setItem('linkedin_user', value);
    }
  }
  public redirectToEsqueciMinhaSenha() {
    const frontBase =
      this.tabActive == 0 ? siteCandidateEndPoint : siteCompanyEndPoint;
    window.location.href = `${frontBase}/forgot?lang=${this.$i18n.locale}`;
  }
  public redirectToRegistrarAgora() {
    if (this.tabActive == 0) {
      const frontBase = siteCandidateEndPoint;
      window.location.href = `${frontBase}/register?lang=${this.$i18n.locale}`;
    }
    else {
      if (this.$route.name === 'HomeEmpresa') {
        // Já está na página, apenas fechar modal
        this.$bvModal.hide('modal-login');
        this.focusNoFormCadastro();
      }
      else {
        this.$router.push({ name: "HomeEmpresa", query: {lang: this.$i18n.locale} });
      }
    }
  }

  public focusNoFormCadastro() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-login') {
        // Depois que a modal já tiver fechado:
        VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
      }
    });
  }
}
