

































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';
import { EMetodoPagamento } from '@/core/Enums/EMetodoPagamento';
import TextInput from '@/components/inputs/TextInput.vue';
import Payment from '@/core/Services/Payments/Payment';
// import CreditCardForm from '@/components/empresa/pagamento/CreditCardForm.vue';
// import CreditCardFormStripe from '@/components/empresa/pagamento/CreditCardFormStripe.vue';
import JBCPayPal from '@/core/Services/Payments/JBCPayPal';
import DuvidaProsseguirModal from '@/components/modal/DuvidaProsseguirModal.vue';

let PIDINTERVAL: number = 0;

@Component({
  components: {
    'app-section': Section,
    SectionHeader,
    PlanCard,
    TextInput,
    // CreditCardForm,
    DuvidaProsseguirModal
  },
})
export default class CadastroPagamento extends Vue {
  private EMetodoPagamento_PayPal: EMetodoPagamento = EMetodoPagamento.PayPal;
  private EMetodoPagamento_Iugu: EMetodoPagamento = EMetodoPagamento.Iugu;
  private EMetodoPagamento_Stripe: EMetodoPagamento = EMetodoPagamento.Stripe;

  private planoCreditosService = new PlanoCredito();
  private planoEscolhido: PlanoCreditoEntity|null = null;
  private metodoPagamento: EMetodoPagamento = EMetodoPagamento.Iugu;
  private myPopUp: Window|null = null;
  private doneAction: boolean = false;

  private language: any|null = null;

  private get periodoJobcoins() {
    return this.planoEscolhido && this.planoEscolhido.period === EPeriodoPlano.Mensal ? '' : 'Mensais';
  }

  private get periodoHeader() {
    return this.planoEscolhido && this.planoEscolhido.period === EPeriodoPlano.Mensal ? 'mensal' : 'anual';
  }

  private get planoAnual(): boolean 
  {
    return (this.planoEscolhido && this.planoEscolhido.period === EPeriodoPlano.Anual)||false;
  }

  public setMetodoPagamento(metodo: EMetodoPagamento) {
    this.metodoPagamento = metodo;
  }

  public mounted() {
    setTimeout(() => {
      if(!this.doneAction) {
        this.$bvModal.show('modal-duvida-prosseguir');
      }
    },60000)    
  }

  public created() {
    const planoId = sessionStorage.getItem('planoId');
    this.language = this.$i18n;

    if (!planoId || !sessionStorage.getItem('access_token')) {
      // Ainda não preencheu o primeiro form, redirecionar para a home onde o form está
      sessionStorage.removeItem('registroEmpresa')
      sessionStorage.removeItem('dadosEmpresa')
      this.$router.push({ name: 'CadastroDadosDeAcesso', query: {lang: this.$i18n.locale} });
      return;
    }

    if(this.$i18n.locale!='pt-BR'){
      this.$router.push({ name: 'CadastroPagamentoSucesso', query: {lang: this.$i18n.locale} });
      return;
    }

    this.planoCreditosService.fetch(+planoId)
      .then((data: PlanoCreditoEntity) => {
        this.planoEscolhido = data;
      })
      .catch((err: any) => {
      });
  }

  public checkClosedWindow(w: Window|null, func: Function) {
    if (w !== null && w.closed) {
      func.call(0);
      clearInterval(PIDINTERVAL);
    }
  }

  public paymentSubmitted() {
    this.doneAction = true;
  }

  public paypalCall(){
    this.doneAction = true;
    /* $.alert({
      title: ""+this.$t('Atenção'),
      content: ""+this.$t('Certifique-se de que pop-up não esteja bloqueado para esta página.'),
      icon: 'far fa-credit-card',
      theme: 'supervan'
    }); */
    // $.blockUI();
    let jbcpp = new JBCPayPal();
    // this.load = true;
    jbcpp.create({ idplano: this.planoEscolhido!.id, lang: this.$i18n.locale }).then((data: any) => {
      // this.load = false;
      console.log(data.url);
      this.myPopUp = window.open(data.url,"PayPal Payment - Jobecam", "width=795, height=590, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no");

      PIDINTERVAL = setInterval(() => {
        this.checkClosedWindow(this.myPopUp, () => {
          // this.paypalService.fetchAll({check:true}).then(() => { colocar aqui push }).catch(() => {nao pagou})
          if (this.planoEscolhido!.free) {
            this.$router.push({ name: 'CadastroPlanoGratuito', query: {lang: this.$i18n.locale} });
            return;
          }
          this.$router.push({ name: 'CadastroPagamentoSucesso', query: {lang: this.$i18n.locale} })
        });
      }, 1000);

      // $.unblockUI();
    })
    .catch((data:any) => {
      // this.load = false;
      console.log(data);
      // $.unblockUI()
    });

  }
}
